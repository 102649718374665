import { useSelector } from "react-redux";
import Flavours from "../../../components/flavours";
import React, { useState, useContext, useEffect } from "react";
import { CartContext } from "../../../components/cartContext";
import { useListDews } from "../layout/resuableEffect";
import Spinner from "../../../components/spinner";
import addServerIcon from "../../../assets/svg-icons/addServericon.svg";
import ram from "../../../assets/svg-icons/ram.svg";
import disk from "../../../assets/svg-icons/disk.svg";
import whiteDisk from "../../../assets/svg-icons/whiteDisk.svg";
import whiteRAM from "../../../assets/svg-icons/whiteRAM.svg";
import whiteCPU from "../../../assets/svg-icons/whiteCPU.svg";
import cpu from "../../../assets/svg-icons/cpu-icon.svg";
import Select from "../../../components/select";
import Input from "../../../components/input";
import closeIcon from "../../../assets/svg-icons/closeBrand.svg";
import { useNavigate } from "react-router-dom";

const BuyVPS = () => {
  document.title = "Buy VPS - CloudVantage";
  const currency = useSelector((state) => state.user.currency);
  const { setTotalPrice, setDuration } = useContext(CartContext); // Added setDuration
  const vpsList = useListDews();
  const vpsMapList = vpsList.results || [];
  const [currentCartItemId, setCurrentCartItemId] = useState(null);
  const navigate = useNavigate();

  const formatName = (name) => {
    return name
      .split(".")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  };

  const addToCart = (flavor) => {
    const updatedFlavor = { ...flavor, paymentMethod: flavor.paymentMethod || "Monthly" };
    sessionStorage.setItem("cart", JSON.stringify([updatedFlavor]));
    setTotalPrice(updatedFlavor.price); 
    setDuration(updatedFlavor.paymentMethod);
    setCurrentCartItemId(flavor.productId);
  };

  const removeFromCart = () => {
    sessionStorage.removeItem("cart");
    setTotalPrice(0);
    setDuration("Monthly"); // Reset duration
    setCurrentCartItemId(null);
  };

  const [customizeModal, setCustomizeModal] = useState(false);
  const [selectedVCPU, setSelectedVCPU] = useState("1");
  const [selectedRAM, setSelectedRAM] = useState("1GB");
  const [selectedStorage, setSelectedStorage] = useState("5GB");
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("Monthly");
  const [customizedFlavour, setCustomizedFlavour] = useState({});

  const toggleCustomizeModal = () => {
    setCustomizeModal(!customizeModal);
  };

  const VCPUOptions = [
    { value: 1, label: "1" },
    { value: 2, label: "2" },
    { value: 4, label: "4" },
    { value: 6, label: "6" },
    { value: 8, label: "8" },
    { value: 12, label: "12" },
    { value: 24, label: "24" },
    { value: 48, label: "48" },
    { value: 64, label: "64" },
    { value: 128, label: "128" },
  ];

  const RAMOptions = [
    { value: "1GB", label: "1GB", unit: 1 },
    { value: "2GB", label: "2GB", unit: 2 },
    { value: "4GB", label: "4GB", unit: 4 },
    { value: "6GB", label: "6GB", unit: 6 },
    { value: "8GB", label: "8GB", unit: 8 },
    { value: "12GB", label: "12GB", unit: 12 },
    { value: "16GB", label: "16GB", unit: 16 },
    { value: "24GB", label: "24GB", unit: 24 },
    { value: "48GB", label: "48GB", unit: 48 },
    { value: "64GB", label: "64GB", unit: 64 },
    { value: "128GB", label: "128GB", unit: 128 },
  ];

  const StorageOptions = [
    { value: "5GB", label: "5GB", unit: 5 },
    { value: "6GB", label: "6GB", unit: 6 },
    { value: "7GB", label: "7GB", unit: 7 },
    { value: "8GB", label: "8GB", unit: 8 },
    { value: "9GB", label: "9GB", unit: 9 },
    { value: "10GB", label: "10GB", unit: 10 },
    { value: "11GB", label: "11GB", unit: 11 },
    { value: "12GB", label: "12GB", unit: 12 },
    { value: "13GB", label: "13GB", unit: 13 },
    { value: "14GB", label: "14GB", unit: 14 },
    { value: "15GB", label: "15GB", unit: 15 },
    { value: "20GB", label: "20GB", unit: 20 },
    { value: "25GB", label: "25GB", unit: 25 },
    { value: "30GB", label: "30GB", unit: 30 },
    { value: "35GB", label: "35GB", unit: 35 },
    { value: "40GB", label: "40GB", unit: 40 },
    { value: "45GB", label: "45GB", unit: 45 },
    { value: "50GB", label: "50GB", unit: 50 },
    { value: "55GB", label: "55GB", unit: 55 },
    { value: "60GB", label: "60GB", unit: 60 },
    { value: "65GB", label: "65GB", unit: 65 },
    { value: "70GB", label: "70GB", unit: 70 },
    { value: "75GB", label: "75GB", unit: 75 },
    { value: "80GB", label: "80GB", unit: 80 },
    { value: "85GB", label: "85GB", unit: 85 },
    { value: "90GB", label: "90GB", unit: 90 },
    { value: "95GB", label: "95GB", unit: 95 },
    { value: "100GB", label: "100GB", unit: 100 },
    { value: "150GB", label: "150GB", unit: 150 },
    { value: "200GB", label: "200GB", unit: 200 },
    { value: "250GB", label: "250GB", unit: 250 },
    { value: "300GB", label: "300GB", unit: 300 },
    { value: "350GB", label: "350GB", unit: 350 },
    { value: "400GB", label: "400GB", unit: 400 },
    { value: "450GB", label: "450GB", unit: 450 },
    { value: "500GB", label: "500GB", unit: 500 },
    { value: "600GB", label: "600GB", unit: 600 },
    { value: "700GB", label: "700GB", unit: 700 },
    { value: "800GB", label: "800GB", unit: 800 },
    { value: "900GB", label: "900GB", unit: 900 },
    { value: "1000GB", label: "1TB", unit: 1000 },
  ];

  const PaymentMethodOptions = [
    { value: "Monthly", label: "Monthly" },
    { value: "Yearly", label: "Yearly" },
  ];

  const calculateEstimatedCost = (vcpu, ram, storage, currency, paymentMethod) => {
    const foundVCPU = vcpu || 1;
    const foundRam = RAMOptions.find((ramOption) => ramOption.value === ram) || {
      unit: 1,
    };
    const foundStorage =
      StorageOptions.find((storageOption) => storageOption.value === storage) || {
        unit: 1,
      };

    const VCPUPrice = currency === "NGN" ? 2500 : 1.8;
    const RAMPrice = currency === "NGN" ? 2400 : 1.6;
    const StoragePrice = currency === "NGN" ? 300 : 0.2;
    const IPAddressPrice = currency === "NGN" ? 3200 : 2.3;

    const vcpuValue = parseFloat(foundVCPU);
    const ramValue = parseFloat(foundRam?.unit);
    const storageValue = parseFloat(foundStorage?.unit);

    let totalCost =
      vcpuValue * VCPUPrice +
      ramValue * RAMPrice +
      storageValue * StoragePrice +
      IPAddressPrice;
    
    const originalTotalCost = 
      vcpuValue * VCPUPrice +
      ramValue * RAMPrice +
      storageValue * StoragePrice +
      IPAddressPrice;

    if (paymentMethod === "Yearly") {
      const yearlyPrice = totalCost * 12;
      const discount = yearlyPrice * 0.01;
      totalCost = yearlyPrice - discount;
    }

    const formattedMonthlyCost = totalCost.toLocaleString("en-US", {
      style: "currency",
      currency: currency === "NGN" ? "NGN" : "USD",
    });


    const formattedOriginalTotalCost = originalTotalCost.toLocaleString("en-US", {
      style: "currency",
      currency: currency === "NGN" ? "NGN" : "USD",
    });


    const customizedFlavour = {
      name: "Customized Flavour",
      price: originalTotalCost,
      vcpu: vcpuValue,
      ramSize: ram,
      diskSize: storage,
      productId: Date.now(),
      isInCart: false,
      type: "Customize",
      nairaPrice: currency === "NGN" ? formattedOriginalTotalCost : undefined,
      dollarPrice: currency === "USD" ? formattedOriginalTotalCost : undefined,
      flavorId: 10, 
      paymentMethod,
    };

    setCustomizedFlavour(customizedFlavour);
    
    return formattedMonthlyCost; 
  };

  const [calculatedEstimate, setCalculatedEstimate] = useState("0.00");

  useEffect(() => {
    const estimate = calculateEstimatedCost(
      selectedVCPU,
      selectedRAM,
      selectedStorage,
      currency,
      selectedPaymentMethod
    );
    setCalculatedEstimate(estimate);
  }, [selectedVCPU, selectedRAM, selectedStorage, currency, selectedPaymentMethod]);

  const handleConfigure = (e) => {
    e.preventDefault();
    addToCart(customizedFlavour);
    navigate("/client-area/configure-vps");
  };

  const [searchText, setSearchText] = useState("");

  const handleSearch = (event) => {
    setSearchText(event.target.value.toLowerCase());
  };

  const filteredStorageOptions = StorageOptions?.filter((option) =>
    option.label.toLowerCase().includes(searchText)
  );

  return (
    <div className="pb-6 min-h-screen relative grid">
      <Spinner loading={useSelector((state) => state.user).loading} />
      <div>
        <div className="w-full py-6 px-8 bg-orange-100 border-2 border-orange-500 dark:bg-transparent rounded-lg mb-6">
          <p className="text-orange-500 text-sm font-medium text-center">
            To select the Windows image, please ensure that your chosen flavor includes at least 4GB of RAM and a 20GB root disk or more.
          </p>
        </div>
        <div>
          <p className="dark:text-white text-sm font-medium">
            Create a Virtual Instance:
          </p>
          <p className="text-black/40 dark:text-white/40 mb-4 text-xs">
            Select dew
          </p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4">
          {vpsMapList.length > 0 &&
            vpsMapList.map((val, key) => {
              const price =
                currency === "NGN"
                  ? val.naira_price.toLocaleString("en-US", {
                      style: "currency",
                      currency: "NGN",
                    })
                  : val.dollar_price.toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                    });

              return (
                <React.Fragment key={key}>
                  <Flavours
                    productId={val.id}
                    name={formatName(val.name)}
                    price={price}
                    keys={key}
                    nairaPrice={val.naira_price.toLocaleString("en-US", {
                      style: "currency",
                      currency: "NGN",
                    })}
                    dollarPrice={val.dollar_price.toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                    })}
                    vcpu={val.vcpus}
                    ramSize={val.ram}
                    diskSize={val.disk}
                    addToCart={addToCart}
                    removeFromCart={removeFromCart}
                    isInCart={currentCartItemId === val.id}
                    flavorId={val.flavor_id}
                  />
                  {key === vpsMapList.length - 1 && (
                    <div
                      className="border-[3px] border-dashed border-[#c4c4c450] dark:border-[#c4c4c416] dark:text-white rounded p-4 hover:bg-white dark:hover:bg-[#ffffff08] flex items-center justify-center cursor-pointer"
                      onClick={toggleCustomizeModal}
                    >
                      <div className="grid gap-4 text-sm text-center">
                        <img
                          src={addServerIcon}
                          alt="icon-animated"
                          className="h-10 justify-self-center"
                        />
                        <span>Customize your flavour!</span>
                        <div className="flex gap-3 justify-center">
                          <div>
                            <img
                              src={cpu}
                              alt=""
                              className="h-5 md:h-[18px] dark:hidden"
                            />
                            <img
                              src={whiteCPU}
                              alt=""
                              className="h-5 md:h-[18px] hidden dark:block"
                            />
                          </div>
                          <div>
                            <img
                              src={disk}
                              alt=""
                              className="h-5 md:h-[18px] dark:hidden"
                            />
                            <img
                              src={whiteDisk}
                              alt=""
                              className="h-5 md:h-[18px] hidden dark:block"
                            />
                          </div>
                          <div>
                            <img
                              src={ram}
                              alt=""
                              className="h-5 md:h-[18px] dark:hidden"
                            />
                            <img
                              src={whiteRAM}
                              alt=""
                              className="h-5 md:h-[18px] hidden dark:block"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </React.Fragment>
              );
            })}
        </div>
      </div>

      {customizeModal && (
        <div className="fixed w-full bg-black/60 top-0 left-0 z-50 h-screen">
          <div className="flex h-full items-center justify-center p-4">
            <div className="bg-white dark:bg-[#141D26] dark:text-white w-full md:w-1/3 rounded max-h-[100%] md:max-h-[90%]">
              <div className="px-6 md:px-10 py-6 border-b dark:border-b-[#c4c4c432] flex justify-between items-center">
                <span className="text-primaryBlue font-medium">
                  Customize your flavour
                </span>
                <span onClick={toggleCustomizeModal} className="cursor-pointer">
                  <img src={closeIcon} alt="Close" className="h-5" />
                </span>
              </div>
              <div className="py-10 grid gap-4">
                <div className="px-6 md:px-10 grid gap-4">
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <Select
                      label={"VCPUs"}
                      name="protocol"
                      options={VCPUOptions}
                      onChange={(e) => setSelectedVCPU(e.target?.value)}
                    />
                    <Select
                      label={"RAM"}
                      name="protocol"
                      options={RAMOptions}
                      onChange={(e) => setSelectedRAM(e.target?.value)}
                    />
                    <Select
                      label={"Storage"}
                      name="storage"
                      options={filteredStorageOptions}
                      handleSearch={handleSearch}
                      value={selectedStorage}
                      onChange={(e) => setSelectedStorage(e.target.value)}
                      isSearch={true}
                      valueKey="value"
                      labelKey="label"
                    />
                    <Select
                      label={"Payment Method"}
                      name="paymentMethod"
                      options={PaymentMethodOptions}
                      onChange={(e) => setSelectedPaymentMethod(e.target?.value)}
                    />
                  </div>
                </div>
                <div className="px-6 md:px-10 grid gap-2">
                  <div>
                    <div className="text-xs">Estimated cost:</div>
                    <div className="font-semibold crimson-font text-primaryBlue text-lg">
                      {calculatedEstimate}
                    </div>
                  </div>
                  <button
                    type="submit"
                    className="text-sm md:text-xs rounded text-white bg-[#1DA1F2] px-8 py-4 cursor-pointer w-fit"
                    onClick={handleConfigure}
                  >
                    Configure
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default BuyVPS;