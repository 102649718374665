import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit";
import { apiEndPoints } from "../remote/apiEndPoints";
import { APP_SECRET_KEY, showErrorMessage, showSuccessMessage } from "../constants";
import { retrieveFromLocalStorage } from "../constants";
import CryptoJS from "crypto-js";

const initialState = {
  users: null,
  loading: false,
  error: null,
  isAuthenticated: false,
  selectedProject: null,
  images: [],
  imageDetails: [],
  keypairs: [],
  virtualAccount: [],
  securityGroups: [],
  creditHistories: [],
  paymentHistories: [],
  invoices: [],
  userResourceSubscriptions: [],
  currency: localStorage.getItem("currency") || "NGN",
  ...retrieveFromLocalStorage([
    "userSessionData",
    "userProjectList",
    "selectedProject",
  ]),
};

const saveToLocalStorage = (key, data) => { 
  const encryptedData = CryptoJS.AES.encrypt(data, APP_SECRET_KEY).toString();
  localStorage.setItem(key, encryptedData);
};
const removeFromLocalStorage = (key) => {
  localStorage.removeItem(key);
};

export const registerUser = createAsyncThunk(
  "user/registration",
  async (userData, { rejectWithValue }) => {
    try {
      const userRegistrationAPI = await apiEndPoints.userRegistration(userData);
      const response = await userRegistrationAPI.data;
      return response;
    } catch (error) {
      return rejectWithValue({ data: error.response.data });
    }
  }
);

export const createUserVirtualAccount = createAsyncThunk(
  "user/createVirtualAccount",
  async (userData, { rejectWithValue }) => {
    try {
      const virtualAccountAPI =
        await apiEndPoints.createVirtualAccount(userData);
      const response = await virtualAccountAPI.data;
      return response;
    } catch (error) {
      return rejectWithValue({ data: error.response.data });
    }
  }
);

export const createSessionVirtualAccount = createAsyncThunk(
  "user/createSessionVirtualAccount",
  async (userData, { rejectWithValue }) => {
    try {
      const sessionVirtualAccountAPI =
        await apiEndPoints.createSessionVirtualAccount(userData);
      const response = await sessionVirtualAccountAPI.data;
      return response;
    } catch (error) {
      return rejectWithValue({ data: error.response.data });
    }
  }
);

export const getUserVirtualAccount = createAsyncThunk(
  "user/getUserVirtualAccount",
  async () => {
    try {
      const apiGetVirtualAccount = await apiEndPoints.getVirtualAccount();

      const response = (await apiGetVirtualAccount.data) || [];
      return response;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const userAuthenticate = createAsyncThunk(
  "user/userLogin",
  async (userData, { rejectWithValue }) => {
    try {
      const apiUserLogin = await apiEndPoints.userSignIn(userData);
      const response = await apiUserLogin.data;
      saveToLocalStorage("userSessionData", JSON.stringify(response));
      return response;
    } catch (error) {
      return rejectWithValue({ data: error.response.data });
    }
  }
);

export const listDews = createAsyncThunk(
  "user/ListDews",
  async ({ navigate } = {}, { rejectWithValue }) => {
    try {
      const apiListDews = await apiEndPoints.listFlavours();
      const response = await apiListDews.data;
      return response;
    } catch (error) {
      return rejectWithValue({ data: error.response.data, navigate });
    }
  }
);

export const listUserProjects = createAsyncThunk(
  "user/ListUserProjects",
  async (_, { dispatch }) => {
    try {
      const apiListUserProjects = await apiEndPoints.listUserProjects();
      const response = await apiListUserProjects.data;
      saveToLocalStorage("userProjectList", JSON.stringify(response.projects));
      if (response.projects && response.projects.length > 0) {
        const firstProject = response.projects[0];
        dispatch(setSelectedProject(firstProject));
      }
      return response;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const updateUserProject = createAsyncThunk(
  "user/updateProject",
  async ({ updateProjectData, navigate }, { rejectWithValue }) => {
    try {
      const apiUpdateProject =
        await apiEndPoints.updateUserProjects(updateProjectData);
      const response = await apiUpdateProject.data;
      return response;
    } catch (error) {
      return rejectWithValue({ data: error.response.data, navigate });
    }
  }
);

export const createProject = createAsyncThunk(
  "user/CreateProject",
  async ({ createProjectData, navigate }, { rejectWithValue }) => {
    try {
      const apiCreateProject =
        await apiEndPoints.createProject(createProjectData);
      const response = await apiCreateProject.data;
      return response;
    } catch (error) {
      return rejectWithValue({ data: error.response.data, navigate });
    }
  }
);

export const createServerSnapshot = createAsyncThunk(
  "user/createSnapshot",
  async ({ snapshotData, navigate }, { rejectWithValue }) => {
    try {
      const apiCreateSnapshot = await apiEndPoints.createSnapshot(snapshotData);
      const response = await apiCreateSnapshot.data;
      return response;
    } catch (error) {
      return rejectWithValue({ data: error.response.data, navigate });
    }
  }
);

export const listImages = createAsyncThunk("user/ListImages", async () => {
  try {
    const apiListImages = await apiEndPoints.listImages();
    const response = await apiListImages.data.images;
    return response;
  } catch (error) {
    return error.response.data;
  }
});

export const listKeypairs = createAsyncThunk(
  "user/ListKeypairs",
  async (projectId) => {
    try {
      const apiListKeypairs = await apiEndPoints.listKeypairs(projectId);
      const response = (await apiListKeypairs.data?.keypairs) || [];
      return response;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const listServers = createAsyncThunk(
  "user/ListServers",
  async (projectId) => {
    try {
      const apiListKeypairs = await apiEndPoints.listServers(projectId);
      const response = (await apiListKeypairs.data?.servers) || [];
      return response;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const listImageDetails = createAsyncThunk(
  "user/ListLocalImages",
  async () => {
    try {
      const apiListLocalImageDetails = await apiEndPoints.listLocalImageDetails();
      const response = (await apiListLocalImageDetails.data?.images) || [];
      return response;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const switchUserProject = createAsyncThunk(
  "user/SwitchUserProject",
  async (projectId) => {
    try {
      const apiSwitchProject = await apiEndPoints.switchProject(projectId);
      const response = await apiSwitchProject.data;
      return response;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const createKeypair = createAsyncThunk(
  "user/CreateKeypair",
  async ({ createKeypairData, projectId }, { rejectWithValue }) => {
    try {
      const apiCreateKeypair = await apiEndPoints.createKeypair(
        createKeypairData,
        projectId
      );
      const response = await apiCreateKeypair.data;
      return response;
    } catch (error) {
      return rejectWithValue({ data: error.response.data });
    }
  }
);

export const createServer = createAsyncThunk(
  "user/CreateServer",
  async ({ createServerData, projectId }, { rejectWithValue }) => {
    try {
      const apiCreateServer = await apiEndPoints.createServer(
        createServerData,
        projectId
      );
      const response = await apiCreateServer.data;
      return response;
    } catch (error) {
      return rejectWithValue({ data: error.response.data });
    }
  }
);

export const createSecurityGroup = createAsyncThunk(
  "user/CreateSecurityGroup",
  async ({ securityGroupData, projectId }, { rejectWithValue }) => {
    try {
      const apiCreateSecurityGroup = await apiEndPoints.createSecurityGroup(
        securityGroupData,
        projectId
      );
      const response = await apiCreateSecurityGroup.data;
      return response;
    } catch (error) {
      return rejectWithValue({ data: error.response.data });
    }
  }
);

export const createSecurityGroupRule = createAsyncThunk(
  "user/CreateSecurityGroupRule",
  async (
    { securityGroupRuleData, projectId, securityGroupId },
    { rejectWithValue }
  ) => {
    try {
      const apiCreateSecurityGroupRule =
        await apiEndPoints.createSecurityGroupRule(
          securityGroupRuleData,
          projectId,
          securityGroupId
        );
      const response = await apiCreateSecurityGroupRule.data;
      return response;
    } catch (error) {
      return rejectWithValue({ data: error.response.data });
    }
  }
);

export const listSecurityGroups = createAsyncThunk(
  "user/ListSecurityGroups",
  async (projectId) => {
    try {
      const apiListSecurityGroups =
        await apiEndPoints.listSecurityGroups(projectId);
      const response = await apiListSecurityGroups.data?.security_groups;
      return response;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const deleteSecurityGroup = createAsyncThunk(
  "user/DeleteSecurityGroup",
  async ({ projectId, securityGroupId }, { rejectWithValue }) => {
    try {
      const response = await apiEndPoints.deleteSecurityGroup(
        projectId,
        securityGroupId
      );

      return response;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const deleteSecurityGroupRule = createAsyncThunk(
  "user/DeleteSecurityGroupRule",
  async ({ projectId, securityGroupRuleId }, { rejectWithValue }) => {
    try {
      const response = await apiEndPoints.deleteSecurityGroupRule(
        projectId,
        securityGroupRuleId
      );

      return response;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getSingleSecurityGroup = createAsyncThunk(
  "user/SingleSecurityGroup",
  async ({ projectId, securityGroupId }, { rejectWithValue }) => {
    try {
      const response = await apiEndPoints.getSingleSecurityGroups(
        projectId,
        securityGroupId
      );

      return response;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const createSecurityGroupInstance = createAsyncThunk(
  "user/SecurityGroupIntancce",
  async ({ instanceData, projectId }, { rejectWithValue }) => {
    try {
      const response = await apiEndPoints.createAttachSecurityGroupInstance(
        instanceData,
        projectId
      );

      return response;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getResourceStatus = createAsyncThunk(
  "user/ResourceStatus",
  async ({ serverId }, { rejectWithValue }) => {
    try {
      const response = await apiEndPoints.getResourceStatus(serverId);
      return response;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const userLogOut = createAsyncThunk("user/Logout", async () => {
  try {
    const apiUserLogout = await apiEndPoints.userSignOut();
    const response = await apiUserLogout.data;
    logOutSession();
    return response;
  } catch (error) {
    return error.response.data;
  }
});

export const createContactSupport = createAsyncThunk(
  "user/CreateContactSupport",
  async ({ createContactSupportData }, { rejectWithValue }) => {
    try {
      const apiCreateContactSupport = await apiEndPoints.createContactSupport(
        createContactSupportData
      );
      const response = await apiCreateContactSupport.data;
      return response;
    } catch (error) {
      return rejectWithValue({ data: error.response.data });
    }
  }
);

export const deleteUserProject = createAsyncThunk(
  "user/deleteUserProject",
  async (projectId) => {
    try {
      const apiDeleteProject = await apiEndPoints.deleteProject(projectId);
      const response = await apiDeleteProject.data;
      return response;
    } catch (error) {
      return error.response.data;
    }
  }
);
export const deleteUserServer = createAsyncThunk(
  "user/deleteUserServer",
  async ({ projectId, serverId }) => {
    try {
      const apiDeleteServer = await apiEndPoints.deleteServer(
        projectId,
        serverId
      );
      const response = await apiDeleteServer.data;
      return response;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const stopUserServer = createAsyncThunk(
  "user/stopUserServer",
  async ({ projectId, serverId }) => {
    try {
      const apiStopServer = await apiEndPoints.stopServer(projectId, serverId);
      const response = await apiStopServer.data;
      return response;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const restartUserServer = createAsyncThunk(
  "user/restartUserServer",
  async ({ projectId, serverId }) => {
    try {
      const apiRestartServer = await apiEndPoints.restartServer(
        projectId,
        serverId
      );
      const response = await apiRestartServer.data;
      return response;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const createPasswordResetLink = createAsyncThunk(
  "user/createPasswordResetLink",
  async ({ passwordResetFormData }, { rejectWithValue }) => {
    try {
      const apiPasswordResetLink = await apiEndPoints.passwordResetLink(
        passwordResetFormData
      );
      const response = await apiPasswordResetLink.data;
      return response;
    } catch (error) {
      return rejectWithValue({ data: error.response.data });
    }
  }
);

export const createNewPassword = createAsyncThunk(
  "user/createNewPassword",
  async ({ newPasswordFormData }, { rejectWithValue }) => {
    try {
      const apiNewPasswordCreation =
        await apiEndPoints.setNewPassword(newPasswordFormData);
      const response = await apiNewPasswordCreation.data;
      return response;
    } catch (error) {
      return rejectWithValue({ data: error.response.data });
    }
  }
);


export const updateUserProfile = createAsyncThunk(
  "user/updateUserProfile",
  async ({ updateUserProfile }, { rejectWithValue }) => {
    try {
      const apiUpdateUserProfile =
        await apiEndPoints.updateProfile(updateUserProfile);
      const response = await apiUpdateUserProfile.data;
      return response;
    } catch (error) {
      return rejectWithValue({ data: error.response.data });
    }
  }
);

export const projectVolumeList = createAsyncThunk(
  "user/ProjectVolumeList",
  async (projectId) => {
    try {
      const apiProjectVolumeList = await apiEndPoints.volumeList(projectId);
      const response = await apiProjectVolumeList.data?.volumes;
      return response;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const paymentHistoryList = createAsyncThunk(
  "user/paymentHistoryList",
  async () => {
    try {
      const apipaymentHistoryList = await apiEndPoints.paymentHistory();
      const response = await apipaymentHistoryList.data?.zippy_transactions;
      return response;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const creditHistoryList = createAsyncThunk(
  "user/creditHistoryList",
  async () => {
    try {
      const apiCreditHistoryList = await apiEndPoints.paymentHistory();
      const response = await apiCreditHistoryList.data?.results;
      return response;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const userResourceSubscriptionList = createAsyncThunk(
  "user/userResourceSubscriptionList",
  async () => {
    try {
      const apiuserResourceSubscriptionList =
        await apiEndPoints.userResourceSubscription();
      const response = await apiuserResourceSubscriptionList.data?.results;
      return response;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const invoiceList = createAsyncThunk("user/invoiceList", async () => {
  try {
    const apiInvoiceList = await apiEndPoints.getInvoices();
    const response = await apiInvoiceList.data;
    return response;
  } catch (error) {
    return error.response.data;
  }
});

export const payInvoice = createAsyncThunk(
  "user/payInvoice",
  async ({ payInvoiceFormData }, { rejectWithValue }) => {
    try {
      const apiPayInvoice = await apiEndPoints.payInvoice(
        payInvoiceFormData
      );
      const response = await apiPayInvoice.data;
      return response;
    } catch (error) {
      return rejectWithValue({ data: error.response.data });
    }
  }
);

export const createProjectVolumes = createAsyncThunk(
  "user/createVolume",
  async (data, { rejectWithValue }) => {
    try {
      const projectId = data.projectId;
      const apiCreateProjectVolume = await apiEndPoints.createVolume(
        data,
        projectId
      );
      const response = await apiCreateProjectVolume.data;
      return response;
    } catch (error) {
      return rejectWithValue({ data: error.response.data });
    }
  }
);

export const attachedVolumeToInstance = createAsyncThunk(
  "user/createVolume",
  async (data, { rejectWithValue }) => {
    try {
      const projectId = data.projectId;
      const serverId = data.serverId;
      const apiAttachToInstance = await apiEndPoints.attachInstance(
        data,
        projectId,
        serverId
      );
      const response = await apiAttachToInstance.data;
      return response;
    } catch (error) {
      return rejectWithValue({ data: error.response.data });
    }
  }
);
export const detachedVolumeToInstance = createAsyncThunk(
  "user/createVolume",
  async (data, { rejectWithValue }) => {
    try {
      const projectId = data.projectId;
      const serverId = data.server_id;
      const volumeId = data.volume_id;
      const apiAttachToInstance = await apiEndPoints.detachInstance(
        projectId,
        serverId,
        volumeId
      );
      const response = await apiAttachToInstance.data;
      return response;
    } catch (error) {
      return rejectWithValue({ data: error.response.data });
    }
  }
);
export const createPaymentLink = createAsyncThunk(
  "user/payment",
  async (paymentData, { rejectWithValue }) => {
    try {
      const paymentLinkAPI =
        await apiEndPoints.generatePaymentLink(paymentData);
      const response = await paymentLinkAPI.data;
      return response;
    } catch (error) {
      return rejectWithValue({ data: error.response.data });
    }
  }
);

export const deleteVolumeFromInstance = createAsyncThunk(
  "user/deleteVolumeFromInstance",
  async (data, { rejectWithValue }) => {
    try {
      const projectId = data.projectId;
      const volumeId = data.volumeId;
      const apiAttachToInstance = await apiEndPoints.deleteVolumeFromInstance(
        projectId,
        volumeId
      );
      const response = await apiAttachToInstance.data;
      return response;
    } catch (error) {
      return rejectWithValue({ data: error.response.data });
    }
  }
);

export const switchCurrency = createAsyncThunk(
  "user/switchCurrency",
  async (data) => {
    try {
      const response = await apiEndPoints.switchCurrency(data);
      return response;
    } catch (error) {
      return error.response.data;
    }
  }
);

const logOutSession = () => {
  removeFromLocalStorage("user");
  removeFromLocalStorage("userSessionData");
  removeFromLocalStorage("userProjectList");
  removeFromLocalStorage("selectedProject");
};

const userSlice = createSlice({
  name: "user",
  reducers: {
    setSelectedProject(state, action) {
      state.selectedProject = action.payload;
      saveToLocalStorage("selectedProject", JSON.stringify(action.payload));
    },
    setCurrency(state, action) {
      state.currency = action.payload;
      localStorage.setItem("currency", action.payload);
    },
    userClearLogOut(state) {
      state.isAuthenticated = false; // Set user to logged out
      state.selectedProject = null;
      state.userProjectList = [];
      removeFromLocalStorage("selectedProject");
    },
  },
  initialState: initialState,
  extraReducers: (builder) => {
    builder
      .addCase(registerUser.fulfilled, (state, action) => {
        if (action.payload.statuscode === "00") {
          state.users = action.payload;
          showSuccessMessage("Registration Successful");
        } else {
          state.error = action.payload.statusmessage;
          showErrorMessage(action.payload.statusmessage);
        }
        state.loading = false;
      })
      .addCase(createUserVirtualAccount.fulfilled, (state, action) => {
        if (action.payload.status_code === "200") {
          state.users = action.payload;
        } else {
          state.error = action.payload.message;
          showErrorMessage(action.payload.message);
        }
        state.loading = false;
      })
      .addCase(userAuthenticate.fulfilled, (state, action) => {
        if (action.payload.statuscode === "00") {
          state.users = action.payload;
          state.isAuthenticated = true;
          state.userSessionData = action.payload;
        } else {
          state.error = action.payload.message;
          showErrorMessage(action.payload.message);
        }
        state.loading = false;
      })
      .addCase(userLogOut.fulfilled, (state) => {
        state.selectedProject = null;
        state.isAuthenticated = false;
        state.loading = false;
        state.users = null;
      })
      .addCase(listUserProjects.fulfilled, (state, action) => {
        if (action.payload.statuscode === "00") {
          state.users = action.payload;
          state.userProjectList = action.payload.projects;
        }
        state.loading = false;
      })
      .addCase(updateUserProject.fulfilled, (state, action) => {
        if (action.payload.statuscode === "00") {
          state.users = action.payload;
          showSuccessMessage("Project details updated successfully");
        } else {
          state.error = action.payload.statusmessage;
          showErrorMessage(action.payload.statusmessage);
        }
        state.loading = false;
      })

      .addCase(listImages.fulfilled, (state, action) => {
        state.loading = false;
        state.images = action.payload;
      })
      .addCase(listImageDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.imageDetails = action.payload;
      })
      .addCase(listKeypairs.fulfilled, (state, action) => {
        state.loading = false;
        state.keypairs = action.payload;
      })
      .addCase(getUserVirtualAccount.fulfilled, (state, action) => {
        state.loading = false;
        state.virtualAccount = action.payload;
      })
      .addCase(listSecurityGroups.fulfilled, (state, action) => {
        state.loading = false;
        state.keypairs = action.payload;
      })

      //Fulfilled with notification message
      .addMatcher(
        isAnyOf(
          createProject.fulfilled,
          createKeypair.fulfilled,
          createServerSnapshot.fulfilled,
          createSessionVirtualAccount.fulfilled,
          createContactSupport.fulfilled,
          createSecurityGroup.fulfilled,
          createSecurityGroupRule.fulfilled,
          deleteSecurityGroup.fulfilled,
          deleteSecurityGroupRule.fulfilled,
          createSecurityGroupInstance.fulfilled,
          deleteUserProject.fulfilled,
          deleteUserServer.fulfilled,
          stopUserServer.fulfilled,
          restartUserServer.fulfilled,
          createPasswordResetLink.fulfilled,
          payInvoice.fulfilled,
          createNewPassword.fulfilled,
          createProjectVolumes.fulfilled,
          attachedVolumeToInstance.fulfilled,
          createPaymentLink.fulfilled,
          deleteVolumeFromInstance.fulfilled
        ),
        (state, action) => {
          if (action.payload.statuscode === "00") {
            state.users = action.payload;
            showSuccessMessage(action.payload.statusmessage);
          } else if (action.payload) {
            state.users = action.payload;
            showSuccessMessage(action.payload.statusmessage || "Successful");
          } else {
            state.error = action.payload.statusmessage;
            showErrorMessage(action.payload.statusmessage);
          }
          state.loading = false;
        }
      )

      //Fulfilled without notification message
      .addMatcher(
        isAnyOf(
          createServer.fulfilled,
          listDews.fulfilled,
          listServers.fulfilled,
          getUserVirtualAccount.fulfilled,
          switchUserProject.fulfilled,
          getSingleSecurityGroup.fulfilled,
          getResourceStatus.fulfilled,
          projectVolumeList.fulfilled,
          invoiceList.fulfilled,
          creditHistoryList.fulfilled,
          paymentHistoryList.fulfilled,
          switchCurrency.fulfilled,
        ),
        (state, action) => {
          if (action.payload.statuscode === "00") {
            state.users = action.payload;
          }
          state.loading = false;
        }
      )
      .addMatcher(
        isAnyOf(
          registerUser.pending,
          userAuthenticate.pending,
          listDews.pending,
          listUserProjects.pending,
          updateUserProject.pending,
          createProject.pending,
          listImages.pending,
          listImageDetails.pending,
          listKeypairs.pending,
          createKeypair.pending,
          createServer.pending,
          listServers.pending,
          createServerSnapshot.pending,
          createUserVirtualAccount.pending,
          createSessionVirtualAccount.pending,
          getUserVirtualAccount.pending,
          createContactSupport.pending,
          createSecurityGroup.pending,
          createSecurityGroupRule.pending,
          listSecurityGroups.pending,
          switchUserProject.pending,
          deleteSecurityGroup.pending,
          getSingleSecurityGroup.pending,
          deleteSecurityGroupRule.pending,
          createSecurityGroupInstance.pending,
          getResourceStatus.pending,
          deleteUserProject.pending,
          deleteUserServer.pending,
          restartUserServer.pending,
          stopUserServer.pending,
          createPasswordResetLink.pending,
          payInvoice.pending,
          createNewPassword.pending,
          projectVolumeList.pending,
          invoiceList.pending,
          creditHistoryList.pending,
          paymentHistoryList.pending,
          createProjectVolumes.pending,
          attachedVolumeToInstance.pending,
          createPaymentLink.pending,
          deleteVolumeFromInstance.pending,
          switchCurrency.pending
        ),
        (state) => {
          state.loading = true;
          state.users = null;
          state.error = null;
        }
      )
      .addMatcher(
        isAnyOf(
          registerUser.rejected,
          userAuthenticate.rejected,
          listDews.rejected,
          listUserProjects.rejected,
          updateUserProject.rejected,
          createProject.rejected,
          listImages.rejected,
          listImageDetails.rejected,
          listKeypairs.rejected,
          createKeypair.rejected,
          createServer.rejected,
          listServers.rejected,
          createServerSnapshot.rejected,
          createUserVirtualAccount.rejected,
          createSessionVirtualAccount.rejected,
          getUserVirtualAccount.rejected,
          createContactSupport.rejected,
          createSecurityGroup.rejected,
          createSecurityGroupRule.rejected,
          listSecurityGroups.rejected,
          createSecurityGroupRule.rejected,
          switchUserProject.rejected,
          deleteSecurityGroup.rejected,
          getSingleSecurityGroup.rejected,
          deleteSecurityGroupRule.rejected,
          createSecurityGroupInstance.rejected,
          getResourceStatus.rejected,
          deleteUserProject.rejected,
          deleteUserServer.rejected,
          restartUserServer.rejected,
          stopUserServer.rejected,
          createPasswordResetLink.rejected,
          payInvoice.rejected,
          createNewPassword.rejected,
          projectVolumeList.rejected,
          invoiceList.rejected,
          creditHistoryList.rejected,
          paymentHistoryList.rejected,
          createProjectVolumes.rejected,
          attachedVolumeToInstance.rejected,
          createPaymentLink.rejected,
          deleteVolumeFromInstance.rejected,
          switchCurrency.rejected
        ),
        (state, action) => {
          state.loading = false;
          state.users = null;
          const { data, navigate } = action.payload || {};
          const errorMessage =
            data?.message || data?.statusmessage || "Failed, Try Again";
          if (errorMessage === "Invalid token" && navigate) {
            navigate("/sign-out");
            state.error = "Session timed out. Please sign in to continue.";
          } else {
            state.error = errorMessage;
          }
          showErrorMessage(state.error);
        }
      );
  },
});
export const { setSelectedProject, setCurrency, userClearLogOut } =
  userSlice.actions;
export const userReducer = userSlice.reducer;
