const TermsOfService = () => {
    return ( 
        <div class="text-sm p-4 md:p-6">
        <p class="pb-4 font-bold">Universal Terms of Use</p>

        <p class="pb-4 font-bold text-[#9b9a9a]">
          IMPORTANT: THESE TERMS AND CONDITIONS CONTAIN
          A CLASS ACTION WAIVER. DEPENDING ON THE JURISDICTION, THAT
          SECTION MAY NOT APPLY.
        </p>
    
        <p class="pb-4">
          If you are attempting to access the Cloudvantage Cloud
          Services as a customer of a Cloudvantage Cloud reseller, the
          terms below do not apply to you, and your agreement with your
          reseller governs your use of the Cloudvantage Cloud Services.
        </p>
    
        <p class="pb-4">
          If you signed an Order Form directly with Cloudvantage for
          Cloudvantage Cloud Services, additional terms apply to you as
          stated in the Order Form, and the additional terms and
          conditions listed in the Order Form govern your use of the
          Cloudvantage Cloud Services.
        </p>
    
        <p class=" pb-4 ">
          1. <strong>Terms and Conditions and Related Agreement and Policies;
            Modifications.</strong>
        </p>

        <p class="pl-14 pb-4">
          1. These online Cloudvantage Universal Terms of Use are an
          agreement which sets forth the terms and conditions
          (collectively, the “UToU”) governing access to and the use
          of the products, services, website (
          <a href="https://cloudvantage.com.ng">
            https://cloudvantage.com.ng
          </a>
          ) and/or mobile-optimized versions of the Website to which
          the UToU are linked (collectively, the “Website” and/or
          “Products”), all of which are owned or operated by Company,
          its parent, subsidiaries, affiliates and/or brands, by a
          Website visitor, user and/or the party set forth in the
          related Company order form (“User”), which order is
          incorporated herein by this reference (together with any
          subsequent order forms submitted by User to Company,
          collectively, the “Order”).
        </p>

        <p class="pl-14 pb-4">
          2. The term “Website” also includes and is not limited to any
          subdomains of the Website and any content, code, data,
          services, products, features or functionality made available
          from or through the Website.
        </p>

        <p class="pl-14 pb-4">
          3. Company and User may be individually referred to herein as a
          “Party” and together the “Parties.”
        </p>

        <p class="pl-14 pb-4">
          4. The UToU are effective on the date the User first accessed
          the Website or the date the Company signed the Order,
          whichever is earlier.
        </p>

        <p class="pl-14 pb-4">
          5. User signifies that User has read, understands, acknowledges
          and agrees to be bound by the UToU, along with Company’s
          Acceptable Use Policy (“AUP”) and Privacy Policy (“Privacy
          Policy”), and Order (if applicable), all of which are
          incorporated herein by reference. The UToU, AUP, Privacy
          Policy and Order (if applicable) shall collectively be
          referred to as the “Agreements.” In case of a conflict
          between an Order or term within the AUP, Privacy Policy or
          UToU, the terms of the UToU shall control. Unless otherwise
          set forth in the Order or the UToU, AUP or Privacy Policy,
          the capitalized terms contained within those documents carry
          the same meanings whenever used in the UToU.
        </p>

        <p class="pl-14 pb-4">
          6. The Website and Agreements may be modified at any time by
          Company upon posting to the Website. Any such modifications
          shall be effective immediately. A User’s access and/or use
          of the Website after any modifications to the Website or
          Agreements have been made shall constitute such party’s
          acceptance of each as of the “Last Updated Date” stated
          above.
        </p>
    
        <p class=" pb-4 ">
          2. <strong>Ownership:</strong> Unless otherwise stated, all content included on the Website
          is and shall continue to be the property of Company or its
          content suppliers and is protected under applicable copyright,
          patent, trademark, and other proprietary rights. Any copying,
          redistribution, use or publication of any such content or any
          part of the Website is prohibited, except as expressly
          permitted in the Agreements. Under no circumstances will User
          acquire any ownership rights or other interest in any content
          by or through use of this Website. The term CLOUDVANTAGE and
          the CLOUDVANTAGE logo, and others are either trademarks or
          registered trademarks of Company. Other product and company
          names mentioned on the Website may be trademarks of their
          respective owners.
        </p>
    
    
    
        <p class=" pb-4 ">
          3. <strong>Intended Audience: </strong>The Website and Subscriptions are intended for adults only.
          The Website and Subscriptions are not intended for any
          individual under the age of eighteen (18).
        </p>
    
    
        <p class=" pb-4 ">
          4. <strong>Website Use:</strong> Company grants User a limited, revocable, nonexclusive license
          to use the Website solely for User’s own personal use and not
          for republication, distribution, assignment, sublicense, sale,
          preparation of derivative works, or other use. User agrees not
          to copy materials on the Website, reverse engineer or break
          into the Website, or use materials, products or services in
          violation of any law. The use of the Website is at the
          discretion of Company and Company may terminate User’s use of
          the Website at any time.
        </p>
    
    
        <p class=" pb-4 ">
          5. <strong>XCompliance with Laws:</strong> User agrees to comply with all applicable laws regarding
          User’s use of the Website. User further agrees that
          information provided by User to Company is truthful and
          accurate to the best of User’s knowledge.
        </p>
    
    
        <p class=" pb-4">
          6. <strong>XData Privacy and Protection: </strong>Access and use of the Website involve the processing of
          personal data about visitors, users and/or User. Company’s
          Privacy Policy and Data Processing Addendum (“DPA”), which is
          hereby incorporated by this reference, as applicable, relate
          to personal data and/or personal information, provide User
          contractual assurance that Company has implemented mechanisms
          to ensure the processing of such data and information,
          including transfers of personal data from the European
          Economic Area to a third country, and complies under
          applicable data privacy laws. Company reserves the right, and
          User authorizes Company to use and assign all information
          regarding User’s Website use and all information provided by
          User in any manner consistent with the Privacy Policy. For the
          purposes of the DPA and the Standard Contractual Clauses
          attached to the DPA (when and as applicable) Users (and
          applicable affiliates) are considered the Data Controller or
          Data Exporter.
        </p>
    
    
        <p class=" pb-4">
          7. <strong>Conduct Towards Company:</strong> Company will protect the health, safety, and welfare of
          Company’s employees. Unprofessional conduct, threats, abusive
          language (including, but not limited to, disparaging remarks
          regarding the sex, race, religion, or sexual orientation of
          Company’s employees) and/or anything that could be considered
          hate speech in the course of User’s communications with
          Company will not be tolerated. Such conduct may result in the
          termination of communications and repeated offenses may result
          in the suspension, cancellation or termination of access and
          use of the Website and cancellation of an Order (if
          applicable, and in such instance without any refund to User,
          as determined in Company’s sole and absolute discretion).
        </p>
    
    
        <p class="pb-4">
          8. <strong>Trademark and/or Copyright Claims:</strong> Company supports the protection of intellectual property.
          If
          User would like to submit a trademark or copyright claim,
          please discuss with the Company.
        </p>
    
    
        <p class="pb-4">
          9. <strong>Links To Third-Party Websites: </strong>The Websites may contain links to third-party websites that
          are not owned or controlled by Company. Company assumes no
          responsibility for such content, terms, and conditions,
          privacy policies, or practices of any third-party websites. In
          addition, Company does not censor or edit the content of any
          third-party website. By using the Websites, User expressly
          releases Company from any and all liability arising from User
          use of any third-party website. Accordingly, Company
          encourages User to be aware when User leaves the Website to
          review the terms and conditions, privacy policies, and other
          governing documents of each other website that User may visit.
        </p>
    
    
        <p class="pb-4">
          10. <strong>Feedback:</strong> User understands and agrees that Feedback (as defined below)
          provided by User shall not be treated as confidential
          information (unless Company so agrees in writing), and nothing
          in the UToU or in the Parties’ dealings arising out of or
          related to the UToU will restrict Company’s right to use,
          profit from, disclose, publish, keep secret, or otherwise
          exploit Feedback, without compensating or crediting User.
          “Feedback” refers to any suggestion or idea for improving or
          otherwise modifying the Subscriptions, Documentation and
          Specifications and/or any of Company’s products or services.
        </p>
    
        <p class="pb-4">
          11. <strong>Disclaimer of Representations and Warranties:</strong> THE INFORMATION ON THE WEBSITE IS PROVIDED ON
          AN “AS IS,” “AS
          AVAILABLE” BASIS. USER AGREES THAT USE OF THE WEBSITE IS AT
          USER’S SOLE RISK. COMPANY DISCLAIMS ALL WARRANTIES OF ANY
          KIND, INCLUDING BUT NOT LIMITED TO ANY EXPRESS WARRANTIES,
          STATUTORY WARRANTIES, AND ANY IMPLIED WARRANTIES OF
          MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND
          NON-INFRINGEMENT. TO THE EXTENT USER’S JURISDICTION DOES NOT
          ALLOW LIMITATIONS ON WARRANTIES, THIS LIMITATION MAY NOT APPLY
          TO USER. USER’S SOLE AND EXCLUSIVE REMEDY RELATING TO USER’S
          USE OF THE WEBSITE SHALL BE TO DISCONTINUE USING THE WEBSITE.
        </p>
    
    
        <p class="pb-4">
          12. <strong>LIMITATION OF LIABILITY:</strong> UNDER NO CIRCUMSTANCES WILL COMPANY BE LIABLE OR RESPONSIBLE
          FOR ANY DIRECT, INDIRECT, INCIDENTAL, CONSEQUENTIAL (INCLUDING
          DAMAGES FROM LOSS OF BUSINESS, LOST PROFITS, LITIGATION, OR
          THE LIKE), SPECIAL, EXEMPLARY, PUNITIVE, OR OTHER DAMAGES,
          UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN ANY WAY RELATING
          TO THE WEBSITE, USER’S USE OF THE WEBSITE, OR THE CONTENT,
          EVEN IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. USER’S
          SOLE REMEDY FOR DISSATISFACTION WITH THE WEBSITE AND/OR
          CONTENT IS TO CEASE USE OF THE WEBSITE. User may have
          additional rights under certain laws (including consumer laws)
          which do not allow the exclusion of implied warranties, or the
          exclusion or limitation of certain damages. If these laws
          apply to User, the exclusions or limitations in the Agreements
          that directly conflict with such laws may not apply to User.
        </p>
    
    
        <p class="pb-4">
          13. <strong>Indemnification: </strong>User agrees to indemnify, defend and hold Company and its
          partners, employees, and affiliates, harmless from any
          liability, loss, claim and expense, including reasonable
          attorney’s fees, related to User’s violation of the Agreements
          or use of the Website.
        </p>
    
    
        <p class="pb-4">
          14. <strong>Trial and Class Action Waiver:</strong> PLEASE READ THIS CAREFULLY IF USER IS A RESIDENT OF THE UNITED
          STATES OF AMERICA. IT AFFECTS USER’S LEGAL RIGHTS
        </p>

        <p class="pl-14 pb-4">
    
          1. <strong>Waiver of Jury Trial.</strong> USER HEREBY WAIVE
          USER’S CONSTITUTIONAL AND STATUTORY RIGHTS TO GO TO COURT
          AND HAVE A TRIAL IN FRONT OF A JUDGE OR A JURY. In the event
          any litigation should arise between the Parties in any state
          or federal court in a lawsuit, USER WAIVES ALL RIGHTS TO A
          JURY TRIAL.
        </p>

        <p class="pl-14 pb-4">
          2. <strong>Waiver of Class or Consolidated Actions.</strong>{" "}
          USER UNDERSTANDS AND AGREES ALL CLAIMS AND DISPUTES WHICH
          MAY BE MADE WITHIN THE SCOPE OF THE AGREEMENTS MUST BE
          LITIGATED ON AN INDIVIDUAL BASIS AND NOT ON A CLASS BASIS,
          AND CLAIMS OF MORE THAN ONE USER CANNOT BE LITIGATED JOINTLY
          OR CONSOLIDATED WITH THOSE OF ANY OTHER USER. USER
          UNDERSTANDS AND AGREES THAT, BY ENTERING INTO THE
          AGREEMENTS, THE PARTIES ARE EACH WAIVING TO PARTICIPATE IN A
          CLASS ACTION.
        </p>
    
    
        <p class="pb-4">15. <strong>General Terms.</strong></p>
    
        <p class="pl-14 pb-4">
          1. <strong>Headings.</strong> The headings herein are for
          convenience only and are not part of the Agreements.
        </p>

        <p class="pl-14 pb-4">
          2. <strong>Limitation of Actions.</strong> No action,
          regardless of form, arising by reason of or in connection
          with the Agreements may be brought by either party more than
          one (1) year after the cause of action has arisen.
        </p>

        <p class="pl-14 pb-4">
          3. <strong>Force Majeure.</strong> No delay, failure, or
          default, other than a failure to pay fees when due, will
          constitute a breach of the UToU to the extent caused by acts
          of war, terrorism, hurricanes, earthquakes, other acts of
          God or of nature, strikes or other labor disputes, riots or
          other acts of civil disorder, embargoes, epidemics,
          pandemics, or other causes beyond the performing party’s
          reasonable control.
        </p>

        <p class="pl-14 pb-4">
          4. <strong>Severability.</strong> To the extent permitted by
          applicable law, the Parties hereby waive any provision of
          law that would render any clause of the UToU invalid or
          otherwise unenforceable in any respect. In the event that a
          provision of the UToU is held to be invalid or otherwise
          unenforceable, such provision will be interpreted to fulfill
          its intended purpose to the maximum extent permitted by
          applicable law, and the remaining provisions of the UToU
          will continue in full force and effect.
        </p>

        <p class="pl-14 pb-4">
          5. <strong>No Waiver.</strong> Neither Party will be deemed to
          have waived any of its rights under the UToU by lapse of
          time or by any statement or representation other than by an
          authorized representative in an explicit written waiver. No
          waiver of a breach of the UToU will constitute a waiver of
          any other breach of the UToU.
        </p>

        <p class="pl-14 pb-4">
          6. <strong>Choice of Law and Jurisdiction.</strong> The UToU
          will be governed solely by Nigerian laws, without reference
          to: (a) any conflicts of law principle that would apply the
          substantive laws of another jurisdiction to the Parties’
          rights or duties; (b) the 1980 United Nations Convention on
          Contracts for the International Sale of Goods; or (c) other
          international laws. The Parties consent to the jurisdiction
          of the Nigerian courts.
        </p>

        <p class="pl-14 pb-4">
          7. <strong>Attorney’s Fees and Costs.</strong> Claims for
          attorney’s fees and other costs (including expert fees) are
          recoverable by the prevailing party in any legal action
          associated with the enforcement of the UToU, or the
          collection of any amounts due under the UToU.
        </p>

        <p class="pl-14 pb-4">
          8. <strong>Entire Agreement; Amendments.</strong> Unless
          otherwise stated in the Order (if applicable), the
          Agreements and documents incorporated herein by reference,
          supersedes all prior discussions, negotiations, and
          agreements between the Parties with respect to the subject
          matter hereof, and the Agreements (unless specifically
          stated therein) constitute the sole and entire agreement
          between the Parties with respect to the matters covered
          hereby. No additional terms or conditions relating to the
          subject matter of the Agreements shall be effective unless
          approved in writing by an authorized representative of User
          and Company.
        </p>

        <p class="pl-14 pb-4">
          9. <strong>Translation.</strong> The Agreements are written in
          English. Company may, but is not obligated to, translate the
          terms into other languages. To the extent any translation is
          provided, it is provided for convenience purposes only, and
          in the event of a conflict between a translated version of
          the Agreements and the English, the English version will
          control. Where a translated version is required to be
          provided by law such is to be considered binding in which
          both language versions shall have equal validity; User
          acknowledges that User has reviewed both language versions
          and each is substantially the same in all material respects;
          and in the event of any discrepancy between any versions,
          the translated version may prevail.
        </p>

        <p class="pl-14 pb-4">
          10. <strong>Termination.</strong> Subject to an Order (if
          applicable), Company may terminate the Agreements at any
          time, with or without notice, for any reason.
        </p>

        <p class="pl-14 pb-4">
          11. <strong>Company Contact Information.</strong> If User has
          any questions about the Agreements, please contact Company
          by email at{" "}
          <a href="mailto:support@cloudvantage.com.ng">
            support@cloudvantage.com.ng
          </a>
          .
        </p>
    
      </div>
     );
}
 
export default TermsOfService;