
import cpu from "../../../assets/svg-icons/blueCPU.svg";
import ram from "../../../assets/svg-icons/ram.svg";
import hdd from "../../../assets/svg-icons/disk.svg";
import { useState, useEffect, useContext, useRef } from "react";
import { Backdrop, CircularProgress } from "@mui/material";
import closeIcon from "../../../assets/svg-icons/closeBrand.svg";
import Input from "../../../components/input";
import Select from "../../../components/select";
import ubuntu from "../../../assets/svg-icons/Ubuntu logo.svg";
import debian from "../../../assets/svg-icons/debian logo.svg";
import rocky from "../../../assets/svg-icons/rocky logo.svg";
import centOS from "../../../assets/svg-icons/centOS logo.svg";
import openSUSE from "../../../assets/svg-icons/openSUSE logo.svg";
import fedora from "../../../assets/svg-icons/fedora logo.svg";
import windows from "../../../assets/images/windows.png";
import oracle from "../../../assets/svg-icons/oracle-logo.svg";
import close from "../../../assets/svg-icons/closeBrand.svg";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  listImages,
  listKeypairs,
  createKeypair,
  createServer,
  getResourceStatus,
  listServers,
} from "../../../hooks/local/userReducer";
import { CartContext } from "../../../components/cartContext";
import { extractDiskSize, useListLocalImageDetails, useServerList } from "../layout/resuableEffect";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Spinner from "../../../components/spinner";
import { toast } from "react-toastify"
import { scroller } from "react-scroll"
import { IoCopyOutline } from "react-icons/io5";


const ConfigureVPS = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const isLoading = useSelector((state) => state.user.loading);
  const { images = {} } = useSelector((state) => state.user);
  const { keypairs = {} } = useSelector((state) => state.user);
  const { totalPrice, setTotalPrice, currency, duration, setDuration } =
    useContext(CartContext);

  const [cartItems, setCartItems] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedValue, setSelectedValue] = useState("");
  const [selectedOption, setSelectedOption] = useState("existing");
  const [authMethod, setAuthMethod] = useState("keypairs");
  const [loading, setLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [successDetails, setSuccessDetails] = useState({});
  const [extraVolumeSize, setExtraVolumeSize] = useState("");

  const userServer = useServerList();
  const localImageDetails = useListLocalImageDetails();


  const userServerList = userServer
  ?.filter((server) => server.addresses)
  .map((server) => {
    const externalAddress = server.addresses?.External?.[0]?.addr;
    const allAddresses = Object.values(server.addresses)
      .flat()
      .map((address) => address.addr)
      .join(", ");

    return {
      serverId:  server?.id,
      name: server.name.replace(/_/g, " "),
      address: externalAddress || allAddresses || "",
      status: server.status === "ACTIVE" ? "Active" : "Inactive",
    };
  });



  // Refs to track error fields
  const errorRefs = {
    name: useRef(null),
    imagesRef: useRef(null),
    imageRef: useRef(null),
    old_key_pair_name: useRef(null),
    volume_size: useRef(null),
    keypair_name: useRef(null),
    username: useRef(null),
    user_pass: useRef(null),
  };

  const scrollContainerRef = useRef(null);
  

  const network = "5236d605-3714-41f8-b1c1-6247d3f15cdb";
  const flavorRef = cartItems?.[0]?.productId;
  const flavorId = cartItems?.[0]?.flavorId;
  const storage = cartItems?.[0]?.diskSize;

  const extraStorage = extractDiskSize(storage);

  const selectedProjectId = useSelector(
    (state) => state.user.selectedProject.id
  );

  useEffect(() => {
    const cart = sessionStorage.getItem("cart");
    if (cart) {
      const parsedCart = JSON.parse(cart);
      setCartItems(parsedCart);
      const initialPrice = parsedCart[0]?.price || 0;
      setTotalPrice(initialPrice);
      setDuration(parsedCart[0]?.paymentMethod || "Monthly");
    } else {
      setCartItems([]);
      setTotalPrice(0);
    }
  }, [setTotalPrice, setDuration]);

  // Price calculation logic
  const calculateExtraVolumeCost = (size, currency) => {
    const pricePerGB = currency === "USD" ? 0.2 : 300;
    return size ? parseFloat(size) * pricePerGB : 0;
  };

  const calculateTotalPrice = (cartItems, duration, extraVolumeSize) => {
    let totalCartPrice = 0;
    cartItems.forEach((item) => {
      let priceString = "";
      if (currency === "USD") {
        priceString = item.dollarPrice;
      } else if (currency === "NGN") {
        priceString = item.nairaPrice;
      }
      const price = parseFloat(
        priceString
          ? priceString
              .replace(/NGN|USD|\$/g, "")
              .replace(/,/g, "")
              .trim()
          : item.price || 0
      );

      totalCartPrice += price;
    });

    if (duration === "Yearly") {
      const yearlyPrice = totalCartPrice * 12;
      const discount = yearlyPrice * 0.01;
      totalCartPrice = yearlyPrice - discount;
    }

    const extraVolumeCost = calculateExtraVolumeCost(extraVolumeSize, currency);
    return totalCartPrice + extraVolumeCost;
  };

  // Update totalPrice when duration or extraVolumeSize changes
  useEffect(() => {
    const newTotalPrice = calculateTotalPrice(cartItems, duration, extraVolumeSize);
    setTotalPrice(newTotalPrice);
  }, [cartItems, duration, extraVolumeSize, currency, setTotalPrice]);

  const removeFromCart = () => {
    sessionStorage.removeItem("cart");
    setCartItems([]);
    setTotalPrice(0);

    window.location.reload()
  };

  useEffect(() => {
    dispatch(listImages());
  }, [dispatch]);

  useEffect(() => {
    dispatch(listKeypairs(selectedProjectId));
  }, [dispatch, selectedProjectId]);

  const handleSelect = (duration) => {
    setDuration(duration); 
  };

  useEffect(() => {
    if (cartItems.length > 0 && cartItems[0].paymentMethod) {
      handleSelect(cartItems[0].paymentMethod);
    }
  }, [cartItems]);

  const activeStyle =
    "text-sm bg-primaryBlue/10 rounded px-5 md:px-10 h-14 flex items-center border border-primaryBlue cursor-pointer";
  const inactiveStyle =
    "text-sm bg-[#c4c4c416] rounded px-5 md:px-10 h-14 flex items-center border dark:border-[#c4c4c424] cursor-pointer";

  const formatOptions = (data = []) => {
    return data.map((item) => ({
      value: item?.id,
      label: `${item?.os_distro ? `${item?.os_distro} - ` : ""}${item?.name}`,
    }));
  };

  const formatKeypairOptions = (keypairs = []) => {
    return Array.isArray(keypairs)
      ? keypairs.map(({ keypair }) => ({
          value: keypair?.name,
          label: keypair?.name,
        }))
      : [];
  };

  const keypairOptions = Array.isArray(keypairs)
    ? formatKeypairOptions(keypairs)
    : [];

  const ubuntuOptions = formatOptions(images.ubuntu);
  const debianOptions = formatOptions(images.debian);
  const rockyOptions = formatOptions(images.rocky);
  const centosOptions = formatOptions(images.centos);
  const opensuseOptions = formatOptions(
    images.others?.filter((item) => item.name.includes("openSUSE")) || []
  );
  const fedoraOptions = formatOptions(images["fedora-coreos"]);
  const otherUbuntuOptions = formatOptions(
    images.others?.filter((item) => item.name.includes("Ubuntu")) || []
  );
  const otherCentosOptions = formatOptions(
    images.others?.filter((item) => item.name.includes("CentOS")) || []
  );
  const windowsOptions = formatOptions(
    images.others?.filter((item) => item.name.includes("Windows")) || []
  );
  const oracleOptions = formatOptions(
    images.others?.filter((item) => item.name.includes("Oracle")) || []
  );

  const allUbuntuOptions = [...ubuntuOptions, ...otherUbuntuOptions];
  const allCentosOptions = [...centosOptions, ...otherCentosOptions];

  const getOptionsForSelectedImage = () => {
    switch (selectedImage) {
      case "ubuntu":
        return allUbuntuOptions;
      case "debian":
        return debianOptions;
      case "rocky":
        return rockyOptions;
      case "centos":
        return allCentosOptions;
      case "opensuse":
        return opensuseOptions;
      case "fedora":
        return fedoraOptions;
      case "windows":
        return windowsOptions;
      case "oracle":
        return oracleOptions;
      default:
        return [];
    }
  };

  const handleImageClick = (image) => {
    setSelectedImage(image);
    setSelectedValue("");

    // Default to keypairs for non-Windows
    setAuthMethod(image === "windows" ? "password" : "keypairs"); 
  };

  const createNewKeypair = useFormik({
    initialValues: {
      name: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Keypair Name cannot be empty"),
    }),
    onSubmit: async (values, { resetForm }) => {
      const { name } = values;
      let createKeypairData = { name };
      const { payload } = await dispatch(
        createKeypair({ createKeypairData, projectId: selectedProjectId })
      );
      if (payload) {
        dispatch(listKeypairs(selectedProjectId));
        downloadPrivateKey(payload.keypair);
        resetForm();
      }
    },
  });

  const downloadPrivateKey = (keypair) => {
    const privateKey = keypair.private_key;
    const filename = `${keypair.name}.pem`;
    const blob = new Blob([privateKey], { type: "application/x-pem-file" });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = filename;
    link.click();
  };

  const handlePaymentDuration = (duration) => {
    if (!duration) return duration;
    return duration.charAt(0).toLowerCase() + duration.slice(1);
  };

  
  const [selectedImageRef, setSelectedImageRef] = useState("");

  const linuxImages = ["fedora", "ubuntu", "rocky", "opensuse", "debian", "centos", "oracle"];

  const createNewServer = useFormik({

    initialValues: {
      name: "",
      imagesRef: "",
      imageRef: "",
      old_key_pair_name: "",
      username: "",
      user_pass: "",
    },

    validationSchema: Yup.lazy(() =>
      Yup.object({
        name: Yup.string().required("Instance Name cannot be empty"),
        imagesRef: Yup.string().required("Please select an image"),
        imageRef: Yup.string().required("Image version cannot be empty"),
        old_key_pair_name: linuxImages.includes(selectedImage) && authMethod === "keypairs"
          ? Yup.string().required("Key pair cannot be empty")
          : Yup.string().notRequired(),
        username: selectedImage === "windows" || (linuxImages.includes(selectedImage) && authMethod === "password")
          ? Yup.string().required("Username is required")
          : Yup.string().notRequired(),
          user_pass: selectedImage === "windows" || (linuxImages.includes(selectedImage) && authMethod === "password")
          ? Yup.string()
              .required("Password is required")
              .min(8, "Password must be at least 8 characters long")
              .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
              .matches(/[a-z]/, "Password must contain at least one lowercase letter")
              .matches(/[0-9]/, "Password must contain at least one digit")
              .matches(/[!@#$%^&*(),.?":{}|<>]/, "Password must contain at least one special character")
              .test('no-username', 'Password must not contain any part of the username', function(value) {
                const username = this.parent.username;
                return !username || !value || !value.toLowerCase().includes(username.toLowerCase());
              })
          : Yup.string().notRequired(),
      })
    ),

    onSubmit: async (values, { resetForm, setTouched }) => {
      const { name, imageRef, old_key_pair_name, volume_size, username, user_pass } = values;

      function extractNumber(str) {
        if (!str) return 0; 
        const cleanedStr = str.replace(/GB/gi, "").trim(); 
        return parseInt(cleanedStr, 10) || 0; 
      }



      const numericVolumeSize = extractNumber(volume_size);

      setTouched({
        name: true,
        imagesRef: true,
        imageRef: true,
        old_key_pair_name: true,
        volume_size: true,
        username: true,
        user_pass: true,
      });


      const errors = await createNewServer.validateForm();

      const fieldOrder = [
        "imagesRef",
        "imageRef",
        "name",
        "old_key_pair_name",
        "volume_size",
        "username",
        "user_pass", 
      ];

      // Find the first error field in the defined order
      const firstErrorField = fieldOrder.find(
        (field) => errors[field]
      );

      // Scroll to the first error if it exists
      if (firstErrorField && errorRefs[firstErrorField]?.current) {

          const element = errorRefs[firstErrorField].current;

          if (!element.id) {
            element.id = `error-${firstErrorField}`;
          }


          scroller.scrollTo(element.id, {
            duration: 1000, 
            delay: 100,
            smooth: "easeInOutQuart", 
            offset: -100, 
            containerId: "scroll-container",
          });
          return;
      }

      const customFlavorDisc = cartItems[0].diskSize?.replace("GB", "");
      const transformCustomFlavorDisc = parseInt(customFlavorDisc);

      const customFlavorRam = cartItems[0].ramSize?.replace("GB", "");
      const transformCustomFlavorRam = parseInt(customFlavorRam);

      let createServerData;

      if (cartItems[0]?.type === "Customize") {
        createServerData = {
          name,
          imageRef,
          network,
          volume_size: numericVolumeSize || undefined,
          custom_flavor_ram: transformCustomFlavorRam,
          custom_flavor_disc: transformCustomFlavorDisc,
          custom_flavor_vpcu: cartItems[0].vcpu,
          payment_method: handlePaymentDuration(duration),
        };

      } else {

        createServerData = {
          name,
          flavorRef,
          imageRef,
          network,
          flavor_id: flavorId,
          volume_size: numericVolumeSize || undefined,
          payment_method: handlePaymentDuration(duration),
        };
      }

      // Add keypair or password details based on image

      if (linuxImages.includes(selectedImage)) {

        if (authMethod === "keypairs") {
          createServerData.old_key_pair_name = old_key_pair_name;

        } else if (authMethod === "password") {

          createServerData.username = username;
          createServerData.user_pass = user_pass;
        }
      } else if (selectedImage === "windows") {

        createServerData.username = username;
        createServerData.user_pass = user_pass;
        createServerData.server_type = "windows";

      }
    
      const { payload } = await dispatch(
        createServer({ createServerData, projectId: selectedProjectId })
      );
      const serverId = payload?.server?.id;



      if (
        payload.statuscode === "00" &&
        payload.statusmessage === "successfuly"
      ) {
        setLoading(true);

        const pollForActiveStatus = async (
          serverId,
          maxRetries = 30,
          delay = 10000
        ) => {
          for (let attempt = 0; attempt < maxRetries; attempt++) {
            const { payload } = await dispatch(getResourceStatus({ serverId }));
            if (
              payload?.data?.status === "active" &&
              payload?.data?.statuscode === "00"
            ) {
              setLoading(false);

              const resourceId = payload?.data?.resource_id;

              const projectId = selectedProjectId;

              // Refetch the server list silently
              const { payload: serverListPayload } = await dispatch(
                listServers(projectId)
              );

              // Process the fresh server list
              const updatedServerList = serverListPayload
                ?.filter((server) => server.addresses)
                .map((server) => {
                  const externalAddress = server.addresses?.External?.[0]?.addr;
                  const allAddresses = Object.values(server.addresses)
                    .flat()
                    .map((address) => address.addr)
                    .join(", ");
                  return {
                    serverId: server?.id,
                    name: server.name.replace(/_/g, " "),
                    address: externalAddress || allAddresses || "",
                    status: server.status === "ACTIVE" ? "Active" : "Inactive",
                  };
                });


              const foundServer = updatedServerList?.find(
                (server) => server.serverId === resourceId
              );

              const filteredImageDetail = localImageDetails?.find(
                (imageDetail) => imageDetail?.image_id == selectedImageRef
              );
              
              setIsSuccess(true);
              
              setSuccessDetails({
                username: selectedImage === "windows" || authMethod === "password" ? username : (filteredImageDetail?.username || selectedImage),
                ipAddress: foundServer?.address || "IP not yet assigned",
                type: selectedImage === "windows" || authMethod === "password" ? "windows" : "normal",
              });

              toast.success("Server created successfully", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: false,
                pauseOnHover: false,
                theme: "colored"
              });



              return;
            }
            await new Promise((resolve) => setTimeout(resolve, delay));
          }
          setLoading(false);
        };
        await pollForActiveStatus(serverId);
      }
    },
  });

  useEffect(() => {
    createNewServer.setFieldValue("imagesRef", selectedImage || "");
  }, [selectedImage]);


  const handleVolumeChange = (e) => {
    setExtraVolumeSize(e.target.value);
    createNewServer.setFieldValue("volume_size", e.target.value);
  };

  const handleCloseModal = () => {
    setIsSuccess(false);
    navigate("/client-area");
    removeFromCart();

    createNewServer.resetForm();
  };


  useEffect(() => {
    const errors = createNewServer.errors;
    const touched = createNewServer.touched;

    // Find the first error field that has been touched
    const firstErrorField = Object.keys(errors).find(
      (field) => touched[field] && errors[field]
    );

    if(selectedImage === null) {

      errorRefs.imagesRef?.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      }); 

    } else if (firstErrorField && errorRefs[firstErrorField]?.current) {

      errorRefs[firstErrorField].current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });

    }

 }, [createNewServer.errors, createNewServer.touched]);


 const handleCopyToClipboard = (title, content) => {

  navigator.clipboard.writeText(content).then(() => {
    toast.info(`${title} content has been copied.`, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: false,
      pauseOnHover: false,
      theme: "colored"
    })
  })

}

  


  return (
    <div className="pb-10 min-h-screen">
      <p className="mb-4">Configure your VPS.</p>

      {cartItems.length > 0 ? (
        <form onSubmit={createNewServer.handleSubmit}>
          <div className="grid grid-cols-12 gap-4">
            <div
              id="scroll-container"
              ref={scrollContainerRef}
              className="col-span-12 lg:col-span-8 border dark:border-[#c4c4c424] dark:text-white bg-white dark:bg-slate-800 rounded-md overflow-clip pb-6"
            >
              <div className="px-4 pt-5 grid md:flex justify-between items-center text-lg crimson-font">
                <span>{cartItems[0].name}</span>
                <span className="font-semibold text-[#1da1fc]">
                  {cartItems.length > 0 ? (
                    <>
                      {currency === "USD" ? "USD " : "₦ "}
                      {totalPrice.toLocaleString()}
                    </>
                  ) : (
                    "No price available"
                  )}
                  <span className="text-sm font-normal text-gray-600">
                    {" "}
                    /{duration.toLowerCase()}
                  </span>
                </span>
              </div>

              <div className="p-4 border-b dark:border-b-[#c4c4c424] bg-[#c4c4c410] grid grid-cols-2 md:grid-cols-3 gap-5 md:gap-4 text-xs mt-3">
                <div className="flex items-center gap-2">
                  <img src={cpu} alt="" className="h-4" />
                  <span>VCPUs: {cartItems[0].vcpu}</span>
                </div>
                <div className="flex items-center gap-2">
                  <img src={ram} alt="" className="h-4" />
                  <span>RAM: {cartItems[0].ramSize}</span>
                </div>
                <div className="flex items-center gap-2">
                  <img src={hdd} alt="" className="h-4" />
                  <span>Root disk: {cartItems[0].diskSize}</span>
                </div>
              </div>

              <div className="border-b dark:border-[#c4c4c424] dark:text-white bg-white dark:bg-slate-800">
                <div className="p-4">
                  <p className="text-sm">1. Select term length</p>
                  <div className="flex items-center mt-2 gap-4">
                    <div
                      onClick={() => handleSelect("Monthly")}
                      className={duration === "Monthly" ? activeStyle : inactiveStyle}
                    >
                      <span>Monthly</span>
                    </div>
                    <div
                      onClick={() => handleSelect("Yearly")}
                      className={duration === "Yearly" ? activeStyle : inactiveStyle}
                    >
                      <span className="flex gap-3 items-center">
                        <span>Yearly</span>
                        <span className="text-[10px] bg-black text-white p-1 rounded">
                          1% discount
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="border-b dark:border-[#c4c4c424] dark:text-white bg-white dark:bg-slate-800">
                <div className="p-4">
                  <div className="w-full" ref={errorRefs.imagesRef}>
                    <p className="text-sm">2. Images</p>
                    <div className="mt-2 grid grid-cols-2 md:grid-cols-4 gap-4">
                      <div
                        className={`dark:bg-white/5 p-4 rounded-md border dark:border-[#c4c4c424] cursor-pointer transition-all text-sm ${
                          selectedImage === "ubuntu"
                            ? "border bg-primaryBlue/10 border-primaryBlue dark:border-primaryBlue dark:bg-primaryBlue/5"
                            : ""
                        }`}
                        onClick={() => handleImageClick("ubuntu")}
                      >
                        <div className="flex justify-center mb-4">
                          <img src={ubuntu} alt="Ubuntu" className="h-10" />
                        </div>
                        <p className="text-center">Ubuntu</p>
                      </div>
                      <div
                        className={`dark:bg-white/5 p-4 rounded-md border dark:border-[#c4c4c424] cursor-pointer transition-all text-sm ${
                          selectedImage === "debian"
                            ? "border bg-primaryBlue/10 border-primaryBlue dark:border-primaryBlue dark:bg-primaryBlue/5"
                            : ""
                        }`}
                        onClick={() => handleImageClick("debian")}
                      >
                        <div className="flex justify-center mb-4">
                          <img src={debian} alt="Debian" className="h-10" />
                        </div>
                        <p className="text-center">Debian</p>
                      </div>
                      <div
                        className={`dark:bg-white/5 p-4 rounded-md border dark:border-[#c4c4c424] cursor-pointer transition-all text-sm ${
                          selectedImage === "rocky"
                            ? "border bg-primaryBlue/10 border-primaryBlue dark:border-primaryBlue dark:bg-primaryBlue/5"
                            : ""
                        }`}
                        onClick={() => handleImageClick("rocky")}
                      >
                        <div className="flex justify-center mb-4">
                          <img src={rocky} alt="Rocky" className="h-10" />
                        </div>
                        <p className="text-center">Rocky</p>
                      </div>
                      <div
                        className={`dark:bg-white/5 p-4 rounded-md border dark:border-[#c4c4c424] cursor-pointer transition-all text-sm ${
                          selectedImage === "centos"
                            ? "border bg-primaryBlue/10 border-primaryBlue dark:border-primaryBlue dark:bg-primaryBlue/5"
                            : ""
                        }`}
                        onClick={() => handleImageClick("centos")}
                      >
                        <div className="flex justify-center mb-4">
                          <img src={centOS} alt="CentOS" className="h-10" />
                        </div>
                        <p className="text-center">CentOS</p>
                      </div>
                      <div
                         className={`dark:bg-white/5 p-4 rounded-md border dark:border-[#c4c4c424] cursor-pointer transition-all text-sm ${
                           selectedImage === "oracle"
                             ? "border bg-primaryBlue/10 border-primaryBlue dark:border-primaryBlue dark:bg-primaryBlue/5"
                             : ""
                         }`}
                         onClick={() => handleImageClick("oracle")}
                       >
                         <div className="flex justify-center mb-4">
                           <img src={oracle} alt="Oracle Linux" className="h-10" />
                         </div>
                         <p className="text-center">Oracle Linux</p>
                       </div>
                      <div
                        className={`dark:bg-white/5 p-4 rounded-md border dark:border-[#c4c4c424] cursor-pointer transition-all text-sm ${
                          selectedImage === "opensuse"
                            ? "border bg-primaryBlue/10 border-primaryBlue dark:border-primaryBlue dark:bg-primaryBlue/5"
                            : ""
                        }`}
                        onClick={() => handleImageClick("opensuse")}
                      >
                        <div className="flex justify-center mb-4">
                          <img src={openSUSE} alt="openSUSE" className="h-10" />
                        </div>
                        <p className="text-center">openSUSE</p>
                      </div>
                      <div
                        className={`dark:bg-white/5 p-4 rounded-md border dark:border-[#c4c4c424] cursor-pointer transition-all text-sm ${
                          selectedImage === "fedora"
                            ? "border bg-primaryBlue/10 border-primaryBlue dark:border-primaryBlue dark:bg-primaryBlue/5"
                            : ""
                        }`}
                        onClick={() => handleImageClick("fedora")}
                      >
                        <div className="flex justify-center mb-4">
                          <img src={fedora} alt="Fedora" className="h-10" />
                        </div>
                        <p className="text-center">Fedora</p>
                      </div>
                      {parseInt(cartItems[0]?.ramSize?.replace(/\D/g, "")) >= 4 && parseInt(cartItems[0]?.diskSize?.replace(/\D/g, "")) >= 20 && (
                        <div
                          className={`dark:bg-white/5 p-4 rounded-md border dark:border-[#c4c4c424] cursor-pointer transition-all text-sm ${
                            selectedImage === "windows"
                              ? "border bg-primaryBlue/10 border-primaryBlue dark:border-primaryBlue dark:bg-primaryBlue/5"
                              : ""
                          }`}
                          onClick={() => handleImageClick("windows")}
                        >
                          <div className="flex justify-center mb-4">
                            <img src={windows} alt="Windows" className="h-10" />
                          </div>
                          <p className="text-center">Windows</p>
                        </div>
                      )}
                    </div>
                    {createNewServer.touched.imagesRef && createNewServer.errors.imagesRef && (
                      <span className="text-red-500 text-xs">
                        {createNewServer.errors.imagesRef}
                      </span>
                    )}
                  </div>

                  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-6 mt-10">
                    {selectedImage && (
                      <div ref={errorRefs.imageRef}>
                        <p className="capitalize">{selectedImage}</p>
                        <Select
                          name={"imageRef"}
                          options={[
                            { value: "", label: "Select Image", disabled: true },
                            ...getOptionsForSelectedImage(),
                          ]}
                          value={createNewServer.values.imageRef}
                          onChange={(option) => {
                            createNewServer.setFieldValue("imageRef", option.target.value);
                            setSelectedImageRef(option.target.value);
                          }}
                          onError={
                            createNewServer.errors.imageRef &&
                            createNewServer.touched.imageRef
                              ? createNewServer.errors.imageRef
                              : null
                          }
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="border-b dark:border-[#c4c4c424] dark:text-white bg-white dark:bg-slate-800">
                <div className="p-4">
                  <p className="text-sm">3. Instance name</p>
                  <div className="mt-2 grid grid-cols-1 md:grid-cols-2">
                    <div ref={errorRefs.name}>
                      <Input
                        label={"Name"}
                        type={"text"}
                        name={"name"}
                        value={createNewServer.values.name}
                        onBlur={createNewServer.handleBlur}
                        onChange={createNewServer.handleChange}
                        onError={
                          createNewServer.errors.name && createNewServer.touched.name
                            ? createNewServer.errors.name
                            : null
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="border-b dark:border-[#c4c4c424] dark:text-white bg-white dark:bg-slate-800">
                <div className="p-4">
                  <p className="text-sm">
                    4. Authentication Method
                  </p>

                  {/* Authentication Selection */}
                  {selectedImage && (
                    <div className="mt-4">

                      {/* Radio Buttons for Linux */}
                      {linuxImages.includes(selectedImage) && (
                        <div className="flex flex-col items-start md:flex-row gap-4 mb-4">
                          {/* SSH Keys Option */}
                          <label className={`flex items-start gap-3 p-4 min-h-24 ${authMethod === "keypairs" && "border border-primaryBlue"} rounded-lg cursor-pointer w-full md:w-1/2 hover:border-primaryBlue hover:border`}>
                            <input
                              type="radio"
                              name="authMethod"
                              value="keypairs"
                              checked={authMethod === "keypairs"}
                              onChange={() => setAuthMethod("keypairs")}
                              className="mt-1 w-5 h-5 text-primaryBlue border-gray-300 focus:ring-primaryBlue"
                            />
                            <div className="flex flex-col">
                              <span className="text-base font-medium text-gray-900 dark:text-white">
                                SSH Keys
                              </span>
                              <span className="text-sm text-gray-500 dark:text-gray-400">
                                A more secure authentication method
                              </span>
                            </div>
                          </label>

                          {/* Password Option */}
                          <label className={`flex items-start gap-3 p-4 min-h-24 ${authMethod === "password" && "border border-primaryBlue"} rounded-lg cursor-pointer w-full md:w-1/2 hover:border-primaryBlue hover:border`}>
                            <input
                              type="radio"
                              name="authMethod"
                              value="password"
                              checked={authMethod === "password"}
                              onChange={() => setAuthMethod("password")}
                              className="mt-1 w-5 h-5 text-primaryBlue border-gray-300 focus:ring-primaryBlue"
                            />
                            <div className="flex flex-col">
                              <span className="text-base font-medium text-gray-900 dark:text-white">
                                Password
                              </span>
                              <span className="text-sm text-gray-500 dark:text-gray-400">
                                Create a password to access Dew (less secure)
                              </span>
                            </div>
                          </label>
                        </div>
                      )}


                      {/* Authentication Fields */}
                      {linuxImages.includes(selectedImage) && authMethod === "keypairs" && (
                        <>
                          <div className="flex divide-x text-sm mb-4">
                            <div
                              className={`cursor-pointer pe-4 ${
                                selectedOption === "existing"
                                  ? "text-black dark:text-white"
                                  : "text-[#c4c4c4] dark:text-[#c4c4c450]"
                              }`}
                              onClick={() => setSelectedOption("existing")}
                            >
                              Select from existing keys
                            </div>
                            <div
                              className={`cursor-pointer ps-4 ${
                                selectedOption === "new"
                                  ? "text-black dark:text-white"
                                  : "text-[#c4c4c4] dark:text-[#c4c4c450]"
                              }`}
                              onClick={() => setSelectedOption("new")}
                            >
                              Create new key pair
                            </div>
                          </div>
                          <div className="grid grid-cols-1 md:grid-cols-2 items-end">
                            {selectedOption === "existing" && (
                              <div className="mt-4" ref={errorRefs.old_key_pair_name}>
                                <Select
                                  name={"old_key_pair_name"}
                                  options={[
                                    { value: "", label: "Select Keypair", disabled: true },
                                    ...keypairOptions,
                                  ]}
                                  value={createNewServer.values.old_key_pair_name}
                                  onChange={(option) =>
                                    createNewServer.setFieldValue("old_key_pair_name", option.target.value)
                                  }
                                  onError={
                                    createNewServer.errors.old_key_pair_name &&
                                    createNewServer.touched.old_key_pair_name
                                      ? createNewServer.errors.old_key_pair_name
                                      : null
                                  }
                                />
                              </div>
                            )}
                            {selectedOption === "new" && (
                                <div className="grid gap-4">
                                  <div className="grid gap-4 mt-8" ref={errorRefs.keypair_name}>
                                    <Input
                                      label={"Name"}
                                      type={"text"}
                                      name={"name"}
                                      value={createNewKeypair.values.name}
                                      onBlur={createNewKeypair.handleBlur}
                                      onChange={createNewKeypair.handleChange}
                                      onError={
                                        createNewKeypair.errors.name && createNewKeypair.touched.name
                                          ? createNewKeypair.errors.name
                                          : null
                                      }
                                    />
                                    <div className="grid">
                                      <button
                                        type="button"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          createNewKeypair.handleSubmit(e);
                                        }}
                                        className="text-sm md:text-xs opacity-70 text-white bg-[#1DA1F2] rounded px-8 py-4 cursor-pointer"
                                      >
                                        Create Key
                                      </button>
                                    </div>
                                  </div>
                                </div>
                            )}
                          </div>
                        </>
                      )}

                      {(selectedImage === "windows" || (linuxImages.includes(selectedImage) && authMethod === "password")) && (
                        <div className="mt-4 grid gap-4">
                          <div ref={errorRefs.username}>
                            <Input
                              label={"Username"}
                              type={"text"}
                              name={"username"}
                              value={createNewServer.values.username}
                              onBlur={createNewServer.handleBlur}
                              onChange={createNewServer.handleChange}
                              onError={
                                createNewServer.errors.username && createNewServer.touched.username
                                  ? createNewServer.errors.username
                                  : null
                              }
                            />
                          </div>
                          <div ref={errorRefs.user_pass}>
                            <Input
                              label={"Password"}
                              type={"password"}
                              name={"user_pass"}
                              value={createNewServer.values.user_pass}
                              onBlur={createNewServer.handleBlur}
                              onChange={createNewServer.handleChange}
                              onError={
                                createNewServer.errors.user_pass && createNewServer.touched.user_pass
                                  ? createNewServer.errors.user_pass
                                  : null
                              }
                            />

                            {/* Password Conditions */}
                            <div className="px-6 mt-2 pb-4 text-xs text-black/50 dark:text-white/50">
                              <p className="mb-2">Password must contain:</p>
                              <ul className="list-inside list-disc gap-2 grid">
                                <li>At least 8 characters</li>
                                <li>At least one uppercase letter</li>
                                <li>At least one lowercase letter</li>
                                <li>At least one digit</li>
                                <li>At least one special character</li>
                                <li>Must not contain any part of the username</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  )}


                </div>
              </div>

              <div className="dark:text-white bg-white dark:bg-slate-800">
                <div className="p-4">
                  <p className="text-sm">5. Extra storage (Optional)</p>
                  <div className="mt-2 grid grid-cols-1 md:grid-cols-2">
                    <div ref={errorRefs.volume_size}>
                      <Input
                        type="text"
                        placeholder={`e.g ${storage}`}
                        name={"volume_size"}
                        value={extraVolumeSize}
                        onBlur={createNewServer.handleBlur}
                        onChange={handleVolumeChange}
                        onError={
                          createNewServer.errors.volume_size && createNewServer.touched.volume_size
                            ? createNewServer.errors.volume_size
                            : null
                        }
                      />
                      {storage && (
                        <span className="text-gray-500 text-xs mt-2">
                          Extra storage must not exceed {storage}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-span-12 lg:col-span-4 relative">
              <div className="bg-[#1da1f2] rounded text-white md:sticky md:top-24">
                <div className="border-b border-white/30 p-5">
                  <span className="font-bold crimson-font"> Summary </span>
                </div>
                <div className="p-4 text-sm grid gap-4">
                  <div className="grid grid-cols-2 gap-1">
                    <p>Items:</p>
                    <p className="font-semibold">{cartItems.length}</p>
                  </div>
                  <div className="grid grid-cols-2 gap-1">
                    <p>Selected dew:</p>
                    <p className="font-semibold">{cartItems[0].name}</p>
                  </div>
                  <div className="grid grid-cols-2 gap-1">
                    <p>Term length:</p>
                    <p className="font-semibold">{duration}</p>
                  </div>
                  {extraVolumeSize && (
                    <div className="grid grid-cols-2 gap-1">
                      <p>Extra volume:</p>
                      <p className="font-semibold">{extraVolumeSize}</p>
                    </div>
                  )}
                  {extraVolumeSize && (
                    <div className="grid grid-cols-2 gap-1">
                      <p>Extra Volume Cost:</p>
                      <p className="font-semibold">
                        {currency === "USD" ? "USD " : "₦ "}
                        {calculateExtraVolumeCost(extraVolumeSize, currency).toLocaleString()}
                      </p>
                    </div>
                  )}
                  <div className="grid grid-cols-2 gap-1">
                    <p>Amount:</p>
                    <p className="font-semibold">
                      {currency === "USD" ? "USD " : "₦ "}
                      {totalPrice.toLocaleString()}
                    </p>
                  </div>
                  <div className="mt-4 grid gap-2">
                    <button
                      className="text-primaryBlue bg-white rounded p-4 text-xs w-full"
                      type="submit"
                    >
                      Provision
                    </button>
                    <div
                      onClick={removeFromCart}
                      className="text-center cursor-pointer text-xs p-4 rounded hover:bg-red-500 transition-all"
                    >
                      Remove
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      ) : (
        <div className="flex justify-center items-center h-[400px]">
          <div className="text-center grid gap-2">
            <p className="text-[#1da1f2] text-xl font-medium crimson-font">
              You haven't selected a VPS to configure
            </p>
            <Link
              to={"/client-area/vps"}
              className="dark:text-white/50 text-black/50 text-sm underline"
            >
              Choose and buy a Flavour
            </Link>
          </div>
        </div>
      )}
      {loading && (
        <div>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
          >
            <CircularProgress color="inherit" />
            <p className="ml-3">Creating server...</p>
          </Backdrop>
        </div>
      )}

      {isSuccess && (
        <div className="fixed w-full bg-black/60 top-0 left-0 z-50 h-screen">
          <div className="w-full flex h-full items-center justify-center p-4">
            <div className="bg-white dark:bg-[#141D26] dark:text-white w-full md:w-[40%] rounded max-h-[100%] md:max-h-[90%]">
              <div className="px-6 md:px-10 py-6 border-b dark:border-b-[#c4c4c432] flex justify-between items-center">
                <span className="text-primaryBlue font-medium">
                {selectedImage !== "windows" ? "To login your instance" : "Use Remote Desktop (RDP) to connect:" }
                </span>
                <span onClick={handleCloseModal} className="cursor-pointer">
                  <img src={closeIcon} alt="Close" className="h-5" />
                </span>
              </div>
              <div className="py-10 grid grid-cols-1 gap-4">
                {successDetails.type === "normal" && (
                  <>
                    <div className="px-6 md:px-10 grid gap-4">
                      <div className="bg-gray-300 p-4 rounded-lg w-full flex items-center gap-4">
                        <div className="w-full">
                          <h4 className="text-gray-950 font-medium text-sm">
                            1. Change the permission of your private key
                          </h4>
                          <h4 className="text-gray-950 font-bold text-lg">
                            chmod 400 /path/to/privatekey.pem
                          </h4>
                        </div>
                        <IoCopyOutline
                          className="text-primaryBlue text-xl cursor-pointer"
                          onClick={() =>
                            handleCopyToClipboard("Private Key Permissions", "chmod 400 /path/to/privatekey.pem")
                          }
                        />
                      </div>
                    </div>
                    <div className="px-6 md:px-10 grid gap-4">
                      <div className="bg-gray-300 p-4 rounded-lg w-full flex items-center gap-4">
                        <div className="w-full">
                          <h4 className="text-gray-950 font-medium text-sm">
                            2. Login to your instance doing:
                          </h4>
                          <h4 className="text-gray-950 font-bold text-lg">
                            ssh -i /path/to/privatekey.pem {successDetails?.username}@{successDetails?.ipAddress}
                          </h4>
                        </div>
                        <IoCopyOutline
                          className="text-primaryBlue text-xl cursor-pointer"
                          onClick={() =>
                            handleCopyToClipboard(
                              "Login instance",
                              `ssh -i /path/to/privatekey.pem ${successDetails?.username}@${successDetails?.ipAddress}`
                            )
                          }
                        />
                      </div>
                    </div>
                  </>
                )}
                {successDetails.type === "windows" && (
                  <div className="px-6 md:px-10 gap-4">
                    <div className="bg-gray-300 p-4 rounded-lg w-full  flex items-center gap-4">
                      <div className="w-full">
                        <h4 className="text-gray-950 font-medium text-sm mb-3">
                          {selectedImage !== "windows" ? "Connect to your Instance via:" : "Your Remote Desktop (RDP) credentials to connect with:" }
                        </h4>

                        {selectedImage === "windows" ?
                          <>
                          <h4 className="text-gray-950 font-bold text-lg">
                            Username: {successDetails?.username}
                          </h4>
                          <h4 className="text-gray-950 font-bold text-lg">
                            IP Address: {successDetails?.ipAddress}
                          </h4>
                          
                          </>

                          :

                          <h4 className="text-gray-950 font-bold text-lg">
                            ssh {successDetails?.username}@{successDetails?.ipAddress}
                          </h4>
                          

                        }
                      </div>

                      <IoCopyOutline
                          className="text-primaryBlue text-xl cursor-pointer"
                          onClick={() =>
                            handleCopyToClipboard(
                              `${selectedImage === "windows" ? "Remote Desktop (RDP)" : "Login to your instance" }`, 
                              `ssh${selectedImage === "windows" ? "" : " "}${selectedImage === "windows" 
                                ? `Username: ${successDetails?.username}\nIP Address: ${successDetails?.ipAddress}` 
                                : `${successDetails?.username}@${successDetails?.ipAddress}`}`
                            )
                          }
                        />
                      
                    </div>
                  </div>
                )}
                <div className="px-6 md:px-10 w-full flex justify-end items-end">
                  <button
                    type="submit"
                    className="text-sm md:text-xs rounded text-white bg-[#1DA1F2] px-8 py-4 cursor-pointer w-fit"
                    onClick={handleCloseModal}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ConfigureVPS;

