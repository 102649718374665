import { createContext, useState, useEffect } from "react";
import { useSelector } from "react-redux";

const CartContext = createContext();

const CartProvider = ({ children }) => {
  const currency = useSelector((state) => state.user.currency);
  const [totalPrice, setTotalPrice] = useState(0);
  const [duration, setDuration] = useState("Monthly"); 

 
  useEffect(() => {
    const cart = JSON.parse(sessionStorage.getItem("cart")) || [];
    if (cart.length > 0) {
      const initialPrice = cart[0].price || 0;
      setTotalPrice(initialPrice);
      setDuration(cart[0].paymentMethod || "Monthly");
    } else {
      setTotalPrice(0);
    }
  }, []);

  return (
    <CartContext.Provider
      value={{
        totalPrice,
        setTotalPrice,
        currency,
        duration,
        setDuration,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export { CartContext, CartProvider };