import Input from "../../../components/input";
import { useLocation } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { createNewPassword } from "../../../hooks/local/userReducer";
import { useNavigate } from "react-router-dom";
import Button from "../../../components/button";

const ResetPassword = () => {
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const email = searchParams.get("email");
  const token = searchParams.get("token");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loading = useSelector((state) => state.user.loading);

  const createNewPasswordForm = useFormik({
    initialValues: {
      new_password: "",
      confirm_new_password: "",
    },
    validationSchema: Yup.object({
      new_password: Yup.string().required("Password cannot be empty"),
      confirm_new_password: Yup.string()
        .oneOf([Yup.ref("new_password"), null], "Passwords must match")
        .required("Please confirm your password"),
    }),
    onSubmit: async (values, { resetForm }) => {
      const { new_password } = values;
      let newPasswordFormData = {
        new_password,
        email,
        token,
      };
      const { payload } = await dispatch(
        createNewPassword({ newPasswordFormData, navigate })
      );
      if (payload.statuscode === "00") {
        resetForm();
        navigate("/sign-in");
      }
    },
  });

  

  return (
    <div className="px-4 flex justify-center items-center py-8">
      <div className="my-3 w-full md:w-[50%] lg:w-[40%] ">
        <form onSubmit={createNewPasswordForm.handleSubmit}>
          <div className="bg-white dark:bg-[#141D26] border border-[#c4c4c432] dark:text-white rounded-md px-6 py-10 grid gap-6">
            <div>
              <p className="pb-1 crimson-font text-lg font-semibold">
                Reset password
              </p>
              <p className="text-xs text-[#c4c4c4] pb-2 border-b dark:border-b-[#c4c4c432]">
                Regain access to your account
              </p>
            </div>

            <Input
              label={"New password"}
              type={"password"}
              isPassword={"true"}
              name={"new_password"}
              value={createNewPasswordForm.values.new_password}
              onBlur={createNewPasswordForm.handleBlur}
              onChange={createNewPasswordForm.handleChange}
              onError={
                createNewPasswordForm.touched.new_password &&
                createNewPasswordForm.errors.new_password
              }
            />
            <Input
              label={"Confirm password"}
              type={"password"}
              isPassword={"true"}
              name={"confirm_new_password"}
              value={createNewPasswordForm.values.confirm_new_password}
              onBlur={createNewPasswordForm.handleBlur}
              onChange={createNewPasswordForm.handleChange}
              onError={
                createNewPasswordForm.touched.confirm_new_password &&
                createNewPasswordForm.errors.confirm_new_password
              }
            />

            <div className="grid md:flex md:justify-between gap-6 md:gap-0 items-center">
              <Button
                buttonRole={"submit"}
                loading={loading}
                buttonText={"Reset password!"}
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;
