import React, { useState, useRef, useEffect } from "react";
import { MdKeyboardArrowDown } from "react-icons/md";

const Select = ({
  label,
  name,
  value, 
  onChange,
  onBlur,
  onError,
  options,
  valueKey = "value",
  labelKey = "label",
  searchText,
  handleSearch,
  isSearch,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    if (isSearch) {
      const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
          setIsOpen(false);
        }
      };
      document.addEventListener("mousedown", handleClickOutside);
      return () => document.removeEventListener("mousedown", handleClickOutside);
    }
  }, [isSearch]);

  const filteredOptions = options.filter((option) =>
    option[labelKey].toLowerCase().includes(searchText?.toLowerCase() || "")
  );

  const selectedOption = options.find((opt) => opt[valueKey] === value);


  return (
    <div className="grid gap-1 text-sm dark:text-white">
      <span className="text-sm">{label}</span>
      <div className="relative w-full" ref={isSearch ? dropdownRef : null}>
        {!isSearch ? (
          <select
            name={name}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            className="bg-white text-black w-full p-4 text-sm rounded-md border capitalize border-[#c4c4c460] active:outline-0 focus:outline-0 placeholder:opacity-70 placeholder:text-xs dark:bg-slate-800 dark:text-white"
          >
            {options.map((option, index) => (
              <option key={index} value={option[valueKey]} disabled={option.disabled}>
                {option[labelKey]}
              </option>
            ))}
          </select>
        ) : (
          <div>
            <div
              className="bg-white text-black w-full p-4 text-sm rounded-md border capitalize border-[#c4c4c460] cursor-pointer dark:bg-slate-800 dark:text-white flex justify-between items-center"
              onClick={() => setIsOpen(!isOpen)}
            >
              <span className={selectedOption ? "text-black dark:text-white" : "text-gray-400"}>
                {selectedOption ? selectedOption[labelKey] : `Select ${name}`}
              </span>
              <MdKeyboardArrowDown className="text-gray-500 dark:text-gray-300 text-xl" />
            </div>

            {isOpen && (
              <div className="absolute left-0 mt-2 w-full bg-white dark:bg-slate-800 shadow-md rounded-md border border-[#c4c4c460] z-50">
                <div className="w-full my-2 px-2">
                  <input
                    name={name}
                    value={searchText}
                    placeholder={`Search ${name}`}
                    className="bg-white text-black w-full p-2 text-sm rounded-md border border-gray-300 outline-none dark:bg-slate-700 dark:text-white"
                    onChange={handleSearch}
                    autoFocus
                  />
                </div>

                <div className="max-h-40 overflow-y-auto">
                  {filteredOptions.length > 0 ? (
                    filteredOptions.map((option, index) => (
                      <div
                        key={index}
                        className={`p-2 cursor-pointer hover:bg-gray-200 dark:hover:bg-slate-600 ${
                          value === option[valueKey] ? "bg-gray-300 dark:bg-slate-700" : ""
                        }`}
                        onClick={() => {
                          onChange({ target: { name, value: option[valueKey] } });
                          setIsOpen(false);
                        }}
                      >
                        {option[labelKey]}
                      </div>
                    ))
                  ) : (
                    <div className="p-2 text-sm text-gray-500">No results found</div>
                  )}
                </div>
              </div>
            )}
          </div>
        )}
        <span className="text-red-500 text-xs">{onError}</span>
      </div>
    </div>
  );
};

export default Select;