import PricingCard from "../../components/pricingCard";
import pattern from "../../assets/images/bluePattern.svg";
import { useNavigate } from "react-router-dom";

const Pricing = () => {
  document.title = "Pricing - Cloudvantage";

  const navigate = useNavigate();
  const saveVpsActionOnAuthClick = () => {
    navigate("/sign-in", { state: { actionType: "saveVpsActionOnAuthClick" } });
  };
  return (
    <div className="px-4 md:px-0 py-6 grid gap-6">
      <div className="bg-brandBlue rounded-md grid grid-cols-1 lg:grid-cols-5">
        <div className="lg:col-span-3 px-6 md:px-10 py-10 md:py-16">
          <p className="text-lg leading-snug crimson-font font-bold mb-4">
            Affordable plans for service
          </p>
          <p className="text-sm">
            Explore our services and choose the one that fits your requirements.
            Our team is here to assist you with any questions or custom
            solutions you may need. Contact Us for more information or to get
            started with your chosen plan today!
          </p>
          <p className="text-gray-400 text-sm mt-4">*No card required</p>
        </div>
        <div className="lg:col-span-2 flex items-end md:h-32 lg:h-full overflow-clip">
          <img src={pattern} alt="" className="w-full" />
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        <PricingCard
          category={"Virtual Private Servers (VPS)"}
          price={"13,500"}
          duration={"month"}
          buttonText={"Get started"}
          buttonColor={"#1DA1F2"}
          onClick={saveVpsActionOnAuthClick}
        />
        <PricingCard
          category={"Shared Domain Hosting"}
          price={"25,000"}
          duration={"year"}
          buttonText={"Contact support"}
          buttonColor={"#1DA1F2"}
          actionType={"link"}
          destination={"mailto:support@cloudvantage.com.ng"}
        />
        <PricingCard
          category={"Vantage Coops"}
          price={"15,000"}
          duration={"month"}
          buttonText={"Launch application"}
          buttonColor={"#000000"}
          actionType={"link"}
          destination={"https://vantagecoops.com/"}
        />
      </div>
    </div>
  );
};

export default Pricing;
