import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CryptoJS from "crypto-js";

export const showSuccessMessage = (message) => {
  toast.success(capitalizeString(message));
  return null;
};

export const showErrorMessage = (message) => {
  toast.error(capitalizeString(message));
  return null;
};

const capitalizeString = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const retrieveFromLocalStorage = (keys) => {
  const data = {};
  keys.forEach((key) => {
    const persistedState = localStorage.getItem(key);
    if (persistedState) {
      try {
        const bytes = CryptoJS.AES.decrypt(persistedState, APP_SECRET_KEY);
        const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
        data[key] = decryptedData ? JSON.parse(decryptedData) : null;
        if (typeof data[key] === "string") {
          data[key] = JSON.parse(data[key]);
        }
      } catch (error) {
        data[key] = null; 
      }
    } else {
      data[key] = null;
    }
  });
  return data;
};

export const convertAmountToNumber = (amountString) => {
  if (typeof amountString !== "string") {
    throw new Error("Input must be a string");
  }
  const amountNumber = parseFloat(amountString.replace(/,/g, ""));

  if (isNaN(amountNumber)) {
    throw new Error("Invalid amount string");
  }

  return amountNumber;
};

<i class="fas fa-file-export    "></i>;

export const FormatDate = (dateString) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); 
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

export const formatMonthYear = (dateKey) => {
  // If already formatted, return as is
  if (dateKey?.includes(' ')) return dateKey;
  
  // Only try to format if it's in YYYY-MM format
  if (dateKey?.match(/^\d{4}-\d{2}$/)) {
    const [year, month] = dateKey.split("-");
    const date = new Date(`${year}-${month}-01`);
    return date.toLocaleString("en-US", { 
      month: "long", 
      year: "numeric"
    });
  }
  
  return ""; // Return empty string for invalid formats
};

export const APP_SECRET_KEY = "cl1930474849@#@$@@^@^"