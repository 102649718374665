import { Outlet } from "react-router-dom";
import Navbar from "../../components/navbar";
import Footer from "../../components/footer";
import { Link } from "react-router-dom";
import { useContext, useState } from "react";
import closeIcon from "../../assets/svg-icons/addWhite.svg";
import closeIconBlack from "../../assets/svg-icons/add.svg";
import openMetalLogo from "../../assets/images/openMetal_color.png";
import openMetalLogoWhite from "../../assets/images/openMetal_white.png";
import { ThemeContext } from "../../components/themeContext";

const Layout = () => {
  const [showBanner, setShowBanner] = useState(true);
  const hideBanner = () => {setShowBanner(false);}

  const [showModal, setShowModal] = useState(true);
  const hideModal = () => {setShowModal(false);}

  // Use theme context instead of local state
    const { theme } = useContext(ThemeContext);
    
  return (
    <div className="grid content-between min-h-screen relative">
      {showModal && (
        <div className="w-full fixed lg:hidden h-screen bg-black/70 top-0 left-0 px-4 pt-4 flex items-start md:items-center justify-center z-50">
          <div className="bg-white dark:bg-[#141D26] rounded w-full md:w-[40%] dark:text-white max-h-[80%] mb-4 md:mb-0 overflow-auto px-4 py-8">
            <div className="w-full flex justify-end" onClick={hideModal}>
              <img
                src={theme === "dark" ? closeIcon : closeIconBlack}
                alt="close"
                className="h-5 rotate-45"
              />
            </div>
            <img
              src={theme === "dark" ? openMetalLogoWhite : openMetalLogo}
              alt=""
              className="h-8 object-contain mb-5"
            />
            <div className="text-md font-medium">
              🚀 <br /> Top infrastructure company endorses Cloudvantage as
              Africa's premier hosting provider for infrastructure solutions.
            </div>
            <div className="mt-5">
              <Link
                to={
                  "https://openmetal.io/resources/case-studies/cloudvantage-private-cloud/?hsCtaAttrib=186880824610"
                }
                target="_blank"
                className="underline text-xs"
              >
                Read more...
              </Link>
            </div>
          </div>
        </div>
      )}
      <div className="w-full">
        {showBanner && (
          <div className="bg-black py-4 md:px-10 lg:px-[100px] justify-between items-center hidden lg:flex">
            <div className="w-full overflow-hidden text-white bg-black md:px-10 lg:px-[100px] text-sm">
              <div className="flex w-fit animate-infinite-scroll hover:[animation-play-state:paused] space-x-10 cursor-default">
                <div className="whitespace-nowrap">
                  🚀 Top infrastructure company endorses Cloudvantage as
                  Africa's premier hosting provider for infrastructure
                  solutions.{" "}
                </div>
              </div>
            </div>

            <div onClick={hideBanner} className=" w-[5%] flex justify-end">
              <img
                src={closeIcon}
                alt=""
                className="h-5 cursor-pointer rotate-45"
              />
            </div>
          </div>
        )}
        <Navbar />
      </div>
      <div className="md:px-10 lg:px-[100px] mt-20 md:mt-0">
        <Outlet />
      </div>
      <Footer />
    </div>
  );
};

export default Layout;
