import { useEffect, useState } from "react";
import Input from "../../../components/input";
import { useDispatch, useSelector } from "react-redux";
import { setCurrency, switchCurrency, updateUserProfile } from "../../../hooks/local/userReducer";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Backdrop, CircularProgress } from "@mui/material";
import { toast } from "react-toastify"


const Settings = () => {
  document.title = "Settings - Cloudvantage";
  const dispatch = useDispatch();
  const currency = useSelector((state) => state.user.currency);
  const [currencyPTag, setCurrencyPTag] = useState("");
  const [currencyButtonText, setCurrencyButtonText] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    if (currency === "NGN") {
      setCurrencyPTag("Nigerian Naira (₦)");
      setCurrencyButtonText("Switch to Dollar ($)");
    }
    if (currency === "USD") {
      setCurrencyPTag("United States Dollar ($)");
      setCurrencyButtonText("Switch to Naira (₦)");
    }
  }, [currency]);

  const handleCurrencySwitch = async () => {
    const prefDenomination = currency === "NGN" ? "dollars" : "naira";
    await dispatch(switchCurrency({pref_deno: prefDenomination}));
    const newCurrency = currency === "NGN" ? "USD" : "NGN";
    dispatch(setCurrency(newCurrency));
  };


  const [loading, setLoading] = useState(false);

  const updateUserProfileForm = useFormik({

    initialValues: {
      old_password: "",
      new_password: "",
      confirm_password: "",
    },

    validationSchema: Yup.object({
      old_password: Yup.string().required("Old Password cannot be empty"),
      new_password: Yup.string().required("New Password cannot be empty"),
      confirm_password: Yup.string()
        .oneOf([Yup.ref("new_password"), null], "Passwords must match")
        .required("Please confirm your password"),
    }),
    onSubmit: async (values, { resetForm }) => {
      setLoading(true);

      const { new_password } = values;
      let updateUserProfileFormData = {
        userpass: new_password,
      };
      const { payload } = await dispatch(
        updateUserProfile({ updateUserProfileFormData, navigate })
      );
      if (payload.statuscode === "00") {
        setLoading(false);

        toast.success("Password updated successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: false,
          theme: "colored"
        })

        resetForm();
      }
    },
  });

  return (
    <div className="px-4 md:px-10 py-6 min-h-screen dark:text-white">
      <p className="crimson-font text-xl font-medium text-primaryBlue">
        Settings
      </p>
      <div className="w-full lg:w-[70%] my-10">
        {/* TEAM SETTINGS */}
        {/* <div>
          <div className="flex items-end gap-3 mb-6">
            <div className="w-full">
              <Input label={"Team name"} type={"text"} value={"Default team"} />
            </div>
            <div>
              <button className="bg-primaryBlue text-white px-6 p-4 rounded-md mt-3">
                Update
              </button>
            </div>
          </div>
          <div className="mb-6">
            <Input label={"Team email address"} type={"text"} />
          </div>
          <div className="w-full md:w-2/3 lg:w-1/2">
            <div className="grid">
              <Input label={"Dew limit"} type={"text"} value={"25 Dews"} />
            </div>
            <p className="text-gray-500 mt-2 text-sm">
              Please contact admin to request an increase in Dew limit
            </p>
          </div>
        </div> */}

        {/* SWITCH CURRENCY */}
        <div>
          <p>Switch currency:</p>
          <p className="mt-4 text-sm">
            Product prices will be displayed in{" "}
            <span className="underline underline-offset-4">{currencyPTag}</span>
          </p>
          <button
            onClick={handleCurrencySwitch}
            className="bg-primaryBlue text-white text-xs p-5 rounded-md mt-3"
          >
            {" "}
            {currencyButtonText}{" "}
          </button>
        </div>

        <hr className="my-10 dark:border-[#c4c4c432]" />

        {/* UPDATE PASSPWORD */}

        <div className="">
          <p>Update password:</p>
          <div className="grid gap-4">
            <div className="grid grid-cols-1 gap-4 mt-6 ">
              <div className="">
                <Input 
                  label={"Old password"} 
                  type={"password"}
                  name={"old_password"}
                  value={updateUserProfileForm.values.old_password}
                  onBlur={updateUserProfileForm.handleBlur}
                  onChange={updateUserProfileForm.handleChange}
                  onError={
                    updateUserProfileForm.errors.old_password &&
                    updateUserProfileForm.touched.old_password
                      ? updateUserProfileForm.errors.old_password
                      : null
                  } 
                />
              </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 items-start gap-4">
              <div>
                <Input 
                  label={"New password"} 
                  type={"password"}
                  name={"new_password"}
                  value={updateUserProfileForm.values.new_password}
                  onBlur={updateUserProfileForm.handleBlur}
                  onChange={updateUserProfileForm.handleChange}
                  onError={
                    updateUserProfileForm.errors.new_password &&
                    updateUserProfileForm.touched.new_password
                      ? updateUserProfileForm.errors.new_password
                      : null
                  }  
                />
              </div>
              <div>
                <Input 
                  label={"Confirm password"} 
                  type={"password"}
                  name={"confirm_password"}
                  value={updateUserProfileForm.values.confirm_password}
                  onBlur={updateUserProfileForm.handleBlur}
                  onChange={updateUserProfileForm.handleChange}
                  onError={
                    updateUserProfileForm.errors.confirm_password &&
                    updateUserProfileForm.touched.confirm_password
                      ? updateUserProfileForm.errors.confirm_password
                      : null
                  }  
                />
              </div>
              <div className="text-sm text-gray-500">
                <p>Your password must contain:</p>
                <ul className="list-disc list-inside">
                  <li>at least 8 characters</li>
                  <li>at least 1 special character</li>
                  <li>at least 1 number</li>
                  <li>at least 1 uppercase alphabet</li>
                </ul>
              </div>
            </div>
          </div>
          <button 
            className="bg-primaryBlue text-white text-sm px-6 py-5 rounded-md mt-6"
            onClick={updateUserProfileForm.handleSubmit}
          >
            Update password
          </button>
        </div>
      </div>

      {loading && (
        <div>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
          >
            <CircularProgress color="inherit" />
            <p className="ml-3">Updating password...</p>
          </Backdrop>
        </div>
      )}
    </div>
  );
};

export default Settings;
