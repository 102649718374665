import dewImage from "../../../assets/svg-icons/dewImage.svg";
import Cooperative from "../../../assets/svg-icons/blueCooperative.svg";
import loadBalancer from "../../../assets/svg-icons/blueLoadBalancer.svg";
import arrow from "../../../assets/svg-icons/blueArrow.svg";
import remove from "../../../assets/svg-icons/remove_2.svg";
import close from "../../../assets/svg-icons/closeBrand.svg";
import { Link } from "react-router-dom";
// import add from "../../../assets/svg-icons/addGreen.svg";
import securityGroups from "../../../assets/svg-icons/securityGroups.svg";
import { useServerList } from "../layout/resuableEffect";
import { useState } from "react";
import Modal from "../../../components/modal";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteUserServer,
  listServers,
  restartUserServer,
  stopUserServer,
} from "../../../hooks/local/userReducer";
import Select from "../../../components/select";

const Resources = () => {
  document.title = "Resources - CloudVantage";
  const userServer = useServerList();
  const dispatch = useDispatch();
  const selectedProject = useSelector((state) => state.user.selectedProject);

  const [manageResourceModal, setManageResourceModal] = useState(false);
  const [deleteServerModal, setDeleteServerModal] = useState(false);

  const showManageResourceModal = (key, data) => {
    setManageResourceModal(key);
    const serverDetails = { serverId: data.id, serverName: data.name };
    sessionStorage.setItem("selectedServer", JSON.stringify(serverDetails));
  };

  const closeManageResourceModal = () => {
    setManageResourceModal(false);
  };

  const handleServerAction = async (action, serverId) => {
    const projectId = selectedProject.id;
    const { payload } = await dispatch(action({ projectId, serverId }));

    if (payload.statuscode === "00") {
      await dispatch(listServers(projectId));
      setDeleteServerModal(false);
      setManageResourceModal(false);
      window.location.reload();
    }
  };


  // Usage
  const deleteServer = (serverId) =>
    handleServerAction(deleteUserServer, serverId);
  const restartServer = (serverId) =>
    handleServerAction(restartUserServer, serverId);
  const stopServer = (serverId) => handleServerAction(stopUserServer, serverId);

  const [searchText, setSearchText] = useState("");

  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
    setCurrentPage(1);
  };

  const options = [
    { value: "all-resources", label: "All resources" },
    { value: "name", label: "Name" },
    { value: "status", label: "Status", },
  ];


  const userServerList = userServer
  ?.filter((server) => {
    return server.addresses && Object.values(server.addresses).some(addressList => addressList.length > 0);})
  .map((server) => {
    // rest of your existing mapping code
    const externalAddress = server.addresses?.External?.[0]?.addr;
    const allAddresses = Object.values(server.addresses)
      .flat()
      .map((address) => address.addr)
      .join(", ");

    return {
      name: server.name.replace(/_/g, " "),
      address: externalAddress || allAddresses || "",
      status: server.status === "ACTIVE" ? "Active" : "Inactive",
      id : server.id
    };
  });


  const [currentPage, setCurrentPage] = useState(1);
  const entriesPerPage = 5;

  const filteredData = userServerList?.filter((row) =>
    row?.name?.toLowerCase().includes(searchText.toLowerCase()) ||
    row?.status?.toLowerCase().includes(searchText.toLowerCase()),
  );

  const indexOfLastEntry = currentPage * entriesPerPage;
  const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
  const currentEntries = filteredData.slice(
    indexOfFirstEntry,
    indexOfLastEntry,
  );
  const totalPages = Math.ceil(filteredData.length / entriesPerPage);

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <div>
      <div className="grid gap-2 mb-10">
        <p className="dark:text-white text-sm">Resources:</p>
        {userServer && userServer.length > 0 ? (
          <div className="dark:text-white">
            <div className="bg-white dark:bg-slate-800 px-3 md:px-4 border dark:border-[#c4c4c432] border-b rounded-t-md text-sm flex justify-between items-center">
              <div className="">
                Active servers (Dews) -{" "}
                <span className="text-gray-500">[{userServerList.length}]</span>
              </div>
              <Link
                to={"/client-area/vps"}
                className="cursor-pointer py-4 px-3 flex items-center gap-1"
              >
                <div className="text-primaryBlue">Create dew</div>
                <div>
                  <img src={arrow} alt="" className="h-5" />
                </div>
              </Link>
            </div>

            <div className="my-6 bg-white p-4 rounded-md">
              <div className="grid grid-cols-1 md:grid-cols-12 gap-4 items-end mb-4">
                <div className="md:col-span-3">
                  <Select label="Filter Resources" name="resources" options={options} />
                </div>
                <div className="md:col-span-4">
                  <input
                    type="search"
                    name=""
                    value={searchText}
                    onChange={handleSearchChange}
                    placeholder="Search by name or status..."
                    className="bg-white text-black w-full p-4 text-sm rounded-md border border-[#c4c4c460] active:outline-0 focus:outline-0 placeholder:opacity-70 placeholder:text-xs dark:bg-slate-800 dark:text-white"
                  />
                </div>
            </div>
          
              <div className="bg-white dark:bg-slate-800 px-3 md:px-4 border rounded-b-md dark:border-[#c4c4c432] border-t-2 text-sm">
                {filteredData
                  .map((val, key) => (
                    <div
                      key={key}
                      className="grid grid-cols-3 md:grid-cols-5 justify-between items-center gap-1 md:gap-2 py-4 last:border-none border-b dark:border-b-[#c4c4c432]"
                    >
                      <div className="col-span-3 md:col-span-2 text-primaryBlue capitalize ">
                        {val.name}
                      </div>
                      <div className="md:col-span-1 text-[13px]">
                        {val.address}
                      </div>
                      <div className="md:col-span-1 text-[13px]">
                        {val.status === "Active" ? (
                          <div className="flex gap-1 items-center text-emerald-600">
                            <span className="size-2 rounded-full bg-emerald-500 animate-pulse"></span>
                            <span>Active</span>
                          </div>
                        ) : (
                          <div className="flex gap-1 items-center text-red-600">
                            <span className="size-2 rounded-full bg-red-500"></span>
                            <span>Inactive</span>
                          </div>
                        )}
                      </div>
                      <div className="flex justify-end pe-4 md:col-span-1">
                        <span
                          onClick={() => showManageResourceModal(key, val)}
                          className="px-3 py-2 text-xs dark:text-white cursor-pointer border border-[#c4c4c450] dark:border-[#c4c4c416] rounded hover:bg-primaryBlue/10 hover:border-[#c4c4c405]"
                        >
                          Manage
                        </span>
                      </div>
                      {manageResourceModal === key && (
                        <div className="w-full fixed h-screen bg-black/70 top-0 left-0 px-4 flex items-end md:items-center justify-center z-50">
                          <div className="bg-white dark:bg-[#141D26] rounded w-full md:w-[30%] dark:text-white max-h-[80%] mb-4 md:mb-0 overflow-auto">
                            <div className="flex justify-between items-center text-sm p-6 border-b dark:border-b-[#c4c4c432]">
                              <div className="flex gap-2 items-center">
                                <span className="dark:text-white">
                                  Manage "{val.name}"
                                </span>
                              </div>
                              <img
                                src={close}
                                onClick={closeManageResourceModal}
                                className="h-5 cursor-pointer"
                                alt=""
                              />
                            </div>
                            <div className="p-4 grid gap-1">
                              <Link
                                to={"/client-area/snapshot"}
                                className="flex items-center justify-between hover:rounded border-b dark:border-[#c4c4c424] last:border-none py-4 px-3 hover:bg-primaryBlue/5 transition-all"
                              >
                                <span>Create snapshot</span>
                                <span>
                                  <img src={arrow} alt="" className="" />
                                </span>
                              </Link>
                              <div className="flex items-center justify-between hover:rounded border-b dark:border-[#c4c4c424] last:border-none py-4 px-3 hover:bg-primaryBlue/5 transition-all">
                                <span>Manage snapshot</span>
                                <span>
                                  <img src={arrow} alt="" className="" />
                                </span>
                              </div>
                              <Link
                                to={"/securityGroups"}
                                className="flex items-center justify-between hover:rounded mb-4 dark:border-[#c4c4c424] last:border-none py-4 px-3 hover:bg-primaryBlue/5 transition-all"
                              >
                                <span>Manage security group</span>
                                <span>
                                  <img src={arrow} alt="" className="" />
                                </span>
                              </Link>
                              <div className="grid gap-1">
                                {val.status.toUpperCase() === "ACTIVE" ? (
                                  <div
                                    className="rounded-md bg-red-500/10 py-5 px-3 text-xs cursor-pointer text-red-500 text-center"
                                    onClick={() => stopServer(val.id)}
                                  >
                                    Shutdown server
                                  </div>
                                ) : (
                                  <div
                                    className="rounded-md bg-primaryBlue/10 hover:bg-primaryBlue/20 transition-colors py-5 px-3 text-xs cursor-pointer text-primaryBlue text-center"
                                    onClick={() => restartServer(val.id)}
                                  >
                                    Restart server
                                  </div>
                                )}

                                <div
                                  className="flex justify-self-center text-red-500 rounded-md py-5 px-3 text-center cursor-pointer"
                                  onClick={() => {
                                    setDeleteServerModal(true);
                                  }}
                                >
                                  <span className="hover:underline">
                                    Delete server
                                  </span>
                                </div>
                              </div>
                              <Modal
                                title={"Delete Server"}
                                isVisible={deleteServerModal}
                                onClose={() => setDeleteServerModal(false)}
                              >
                                <div className="text-sm text-center pt-6">
                                  Are you sure you want to delete{" "}
                                  <span className="underline">{val.name}</span> .
                                  This action cannot be undone.
                                </div>
                                <div className="grid gap-4 justify-center py-6">
                                  <button
                                    className="bg-red-500 text-white text-xs px-6 py-4 rounded-sm w-fit"
                                    onClick={() => deleteServer(val.id)}
                                  >
                                    Yes, delete {val.name}
                                  </button>
                                  <button
                                    className="text-[#1DA1F2] text-sm px-6 py-2 hover:underline"
                                    onClick={() => setDeleteServerModal(false)}
                                  >
                                    Cancel
                                  </button>
                                </div>
                              </Modal>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
              </div>

            </div>
            
          </div>
        ) : (
          <div className=" flex justify-between items-center">
            <p className="text-gray-500 text-xl">
              You don't have any resources yet
            </p>
            <Link to={"/client-area/vps"}>
              <button className="text-white p-3 text-xs rounded bg-primaryBlue ">
                Create Server (Dews)
              </button>
            </Link>
          </div>
        )}
      </div>
      <div className="grid gap-4 mb-10">
        <p className="dark:text-white text-sm">Create:</p>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <Link
            to={"/client-area/vps"}
            className="flex gap-4 items-center w-fit"
          >
            <div className="bg-brandBlue dark:bg-brandBlue/10 rounded-md w-20 h-16 flex items-center justify-center">
              <img src={dewImage} alt="" className="h-5" />
            </div>
            <div className="dark:text-white text-sm">
              <p className="">Create Servers (Dew)</p>
              <p className="text-gray-400 text-[13px]">
                Create a new Virtual Private Server (VPS)
              </p>
            </div>
          </Link>
          <Link
            to={"/securityGroups"}
            className="flex gap-4 items-center w-fit"
          >
            <div className="bg-brandBlue dark:bg-brandBlue/10 rounded-md w-20 h-16 flex items-center justify-center">
              <img src={securityGroups} alt="" className="h-5" />
            </div>
            <div className="dark:text-white text-sm">
              <p className="">Security groups</p>
              <p className="text-gray-400 text-[13px]">
                Manage your security groups
              </p>
            </div>
          </Link>

          <Link
            to={"/client-area/volumes"}
            className="flex gap-4 items-center w-fit"
          >
            <div className="bg-brandBlue dark:bg-brandBlue/10 rounded-md w-20 h-16 flex items-center justify-center">
              <img src={Cooperative} alt="" className="h-5" />
            </div>
            <div className="dark:text-white text-sm">
              <p className="">Manage Volumes</p>
              <p className="text-gray-400 text-[13px]">
                Manage your volumes
              </p>
            </div>
          </Link>

          <Link to={"/loadBalancer"} className="flex gap-4 items-center w-fit">
            <div className="bg-brandBlue dark:bg-brandBlue/10 rounded-md w-20 h-16 flex items-center justify-center">
              <img src={loadBalancer} alt="" className="h-5" />
            </div>
            <div className="dark:text-white text-sm">
              <p className="">Create a Load Balancer</p>
              <p className="text-gray-400 text-[13px]">
                Distribute traffic between multiple dews
              </p>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Resources;
