import { useState, useEffect } from "react";
import Select from "../../../components/select";
import arrow from "../../../assets/svg-icons/blueArrow.svg";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useInvoiceList } from "../layout/resuableEffect";
import InvoiceDetails from "../../../components/invoiceDetails";
import { formatMonthYear } from "../../../hooks/constants";

const Billing = () => {
  document.title = "Billing - Cloudvantage";

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  const [selectedInvoice, setSelectedInvoice] = useState(null);

  const currency = useSelector((state) => state.user.currency);
  const [currencyPTag, setCurrencyPTag] = useState("");

  useEffect(() => {
    if (currency === "NGN") {
      setCurrencyPTag("₦");
    }
    if (currency === "USD") {
      setCurrencyPTag("$");
    }
  }, [currency]);



  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
    setCurrentPage(1);
  };

  const options = [
    { value: "all-invoices", label: "All invoices" },
    { value: "dueDate", label: "Due Date" },
    { value: "status", label: "Status", },
  ];

  const invoiceList = useInvoiceList();

  const invoiceListMap = invoiceList && typeof invoiceList === "object"
    ? Object.entries(invoiceList)
      .filter(([key]) => key !== "statuscode" && key !== "statusmessage")
      .map(([dueDate, invoiceData]) => ({
        dueDate: formatMonthYear(dueDate),
        total: `${currencyPTag}${(invoiceData.total || 0).toFixed(2)}`,
        status: invoiceData.all_paid ? "Paid" : "Unpaid",
        invoices: invoiceData.invoices, // Keep the invoices array for reference if needed
        }))
    : [];
  



  const [currentPage, setCurrentPage] = useState(1);
  const entriesPerPage = 5;

  const filteredData = invoiceListMap?.filter((row) =>
    row?.dueDate?.toLowerCase().includes(searchText.toLowerCase()) ||
    row?.status?.toLowerCase().includes(searchText.toLowerCase()),

  );

  const indexOfLastEntry = currentPage * entriesPerPage;
  const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
  const currentEntries = filteredData.slice(
    indexOfFirstEntry,
    indexOfLastEntry,
  );
  const totalPages = Math.ceil(filteredData.length / entriesPerPage);

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };


  const handleViewInvoices = (e, row) => {
    e.preventDefault();
    setSelectedInvoice(row);
  }

  return (
    <div className="min-h-screen px-4 md:px-10 pt-6 pb-10 dark:text-white">
      <p className="crimson-font text-xl font-medium text-primaryBlue">
        Manage your billing
      </p>


      {invoiceListMap?.length > 0 ?
        <div>
          <div className="grid grid-cols-1 md:grid-cols-12 gap-4 items-end my-10">
            <div className="md:col-span-3">
              <Select label="Filter Invoices" name="invoices" options={options} />
            </div>
            <div className="md:col-span-4">
              <input
                type="search"
                name=""
                value={searchText}
                onChange={handleSearchChange}
                placeholder="Search by status or due date..."
                className="bg-white text-black w-full p-4 text-sm rounded-md border border-[#c4c4c460] active:outline-0 focus:outline-0 placeholder:opacity-70 placeholder:text-xs dark:bg-slate-800 dark:text-white"
              />
            </div>
          </div>
          <div className="overflow-x-auto rounded-md border dark:border-[#c4c4c432]">
            <table className="w-full text-sm text-left overflow-hidden">
              <thead className="bg-white dark:bg-slate-800 px-3 md:px-4 border text-gray-400 dark:border-[#c4c4c432] border-b text-sm font-semibold">
                <tr>
                  <th scope="col" className="px-6 py-4 whitespace-nowrap">
                    S/N
                  </th>
                  <th scope="col" className="px-6 py-4 whitespace-nowrap">
                    Due date
                  </th>
                  <th scope="col" className="px-6 py-4 whitespace-nowrap">
                    Total Amount
                  </th>

                  <th scope="col" className="px-6 py-4 whitespace-nowrap">
                    Status
                  </th>
                  <th scope="col" className="px-6 py-4 whitespace-nowrap">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white dark:bg-slate-800 px-3 md:px-4 border rounded-b-md dark:border-[#c4c4c432] border-t-2 text-sm">
                {currentEntries.map((row, index) => (
                  <tr
                    key={index}
                    className="dark:hover:bg-[#ffffff05] hover:bg-brandBlue/25 whitespace-nowrap"
                  >
                    <td className="text-start py-4 px-6">{index + 1}.</td>
                    <td className="text-start py-4 px-6">{row.dueDate}</td>
                    <td className="text-start py-4 px-6">{row.total}</td>
                    <td className="text-start py-4 px-6">{row.status}</td>
                    <td className="text-start py-4 px-6">
                        <button
                          className="text-white p-3 text-xs rounded bg-primaryBlue w-full md:w-fit"
                          onClick={(e) => handleViewInvoices(e, row)}
                        >
                          View Invoices
                        </button>
                    </td>

                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="grid justify-center gap-4 md:flex md:justify-between items-center mt-4">
            <p className="text-gray-400 text-sm">
              Showing{" "}
              <span className="font-medium text-black dark:text-white">
                {indexOfFirstEntry + 1}
              </span>{" "}
              to{" "}
              <span className="font-medium text-black dark:text-white">
                {Math.min(indexOfLastEntry, invoiceListMap?.length)}
              </span>{" "}
              of{" "}
              <span className="font-medium text-black dark:text-white">
                {invoiceListMap?.length}
              </span>{" "}
              results
            </p>
            <div className=" flex gap-3">
              <button
                className={`bg-white dark:bg-primaryBlue border dark:border-none rounded-md py-3 px-5 text-sm ${currentPage === 1 ? "opacity-50" : "opacity-100"}`}
                onClick={handlePreviousPage}
                disabled={currentPage === 1}
              >
                Previous
              </button>
              <button
                className={`bg-white dark:bg-primaryBlue border dark:border-none rounded-md py-3 px-5 text-sm ${currentPage === totalPages ? "opacity-50" : "opacity-100"}`}
                onClick={handleNextPage}
                disabled={currentPage === totalPages}
              >
                Next
              </button>
            </div>
          </div>
        </div>

        :

        <>
          <div className="md:px-52 text-center grid text-gray-500 mt-32">
            <div>It looks like you don't have a billing history yet. Once you make a payment for a service, your billing details will appear here.</div>
            <Link to={"/client-area/vps"} className="border border-[#c4c4c450] dark:border-[#c4c4c416] rounded-md p-3 flex items-center justify-self-center mt-8 w-fit text-xs">
              <span>View VPS plans</span>
              <span className="ps-2">
                <img src={arrow} alt="" />
              </span>
            </Link>
          </div>
        
        </> 
      
      }


      {/* Selected Invoice Table */}

      {selectedInvoice && (
        <div
         className="absolute inset-0 bg-[rgba(0,0,0,0.4)] z-[9999]"
        >
          <InvoiceDetails
            selectedInvoice={selectedInvoice}
            onClose={() => setSelectedInvoice(null)}
          />

        </div>
      )}
      

    </div>
  );
};

export default Billing;
