import { useEffect, useState } from "react";
import Select from "./select";
import arrow from "../assets/svg-icons/blueArrow.svg";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { formatMonthYear } from "../hooks/constants";
import { payInvoice } from "../hooks/local/userReducer";
import InlineSpinner from "./buttonSpinner";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


const InvoiceDetails = ({ selectedInvoice, onClose  }) => {
  document.title = "InvoiceDetails - Cloudvantage";

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const loading = useSelector((state) => state.user.loading);

  const currency = useSelector((state) => state.user.currency);
  const [currencyPTag, setCurrencyPTag] = useState("");

  useEffect(() => {
    if (currency === "NGN") {
      setCurrencyPTag("₦");
    }
    if (currency === "USD") {
      setCurrencyPTag("$");
    }
  }, [currency]);



  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
    setCurrentPage(1);
  };


  const options = [
    { value: "all-invoices", label: "All invoices" },
    { value: "resource", label: "Resource" },
    { value: "resource-name", label: "Resource Name", },
    { value: "dueDate", label: "Due Date", },
  ];

  const invoiceList = selectedInvoice?.invoices || [];

  const invoiceListMap = Array.isArray(invoiceList)
  ? invoiceList.map((invoice, index) => ({
    id: invoice?.invoiceId,
    invoiceNumber: invoice?.display_id,
    resourceName: invoice?.resource?.name,
    resource: invoice?.resource?.resource,
    creationDate: invoice?.created_on,
    dueDate: invoice?.due_date,
    amount: `${currencyPTag}${(invoice?.amount || 0).toFixed(2)}`,
    status: invoice?.status === "unpaid" ? "Unpaid" : "Paid"
  }))
 : [];


  const [currentPage, setCurrentPage] = useState(1);
  const entriesPerPage = 5;

  const filteredData = invoiceListMap?.filter((row) =>
    row.resourceName.toLowerCase().includes(searchText.toLowerCase()) ||
    row.resource.toLowerCase().includes(searchText.toLowerCase()) ||
    row.dueDate.toLowerCase().includes(searchText.toLowerCase()),

  );

  const indexOfLastEntry = currentPage * entriesPerPage;
  const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
  const currentEntries = filteredData.slice(
    indexOfFirstEntry,
    indexOfLastEntry,
  );
  const totalPages = Math.ceil(filteredData.length / entriesPerPage);

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleRowSelection = (id) => {
    setSelectedRows((prev) =>
      prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id]
    );
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedRows([]);
    } else {
      setSelectedRows(currentEntries.map((row) => row.id));
    }
    setSelectAll(!selectAll);
  };

  const totalAmount = selectedRows
  .map((id) => invoiceListMap.find((invoice) => invoice.id === id)?.amount)
  .filter(Boolean)
  .map((amt) => parseFloat(amt.replace(/[^0-9.]/g, "")))
  .reduce((acc, curr) => acc + curr, 0);

  const transformedTotalAmount = `${currencyPTag}${totalAmount.toFixed(2)}`;


  const handleMakePayment = async () => {
    if (selectedRows.length > 0) {

        const payInvoiceFormData = {
          invoice_ids: selectedRows
        }

        const { payload } = await dispatch(payInvoice({ payInvoiceFormData, navigate }));

        if (payload.statuscode === "00") {
            toast.success(payload.message);
            onClose();
        }

        if(payload.statuscode === "01") {
            toast.error(payload.message);
        }
        
        console.log("Processing payment for invoices:", selectedRows);
    }

  };


  return (
    <div className="h-full px-4 md:px-10 pt-6 pb-10 dark:text-white bg-brandBlue">
        <div className="w-full flex justify-end items-end md:p-6 mb-6">
              <button
                className="text-white p-3 text-xs rounded bg-primaryBlue w-fit"
                onClick={onClose}
              >
                Close
              </button>
          </div>

        <p className="crimson-font text-xl font-semibold text-primaryBlue">
            Manage your Invoices Expiring in {formatMonthYear(selectedInvoice?.dueDate)}
        </p>


      {invoiceListMap?.length > 0 ?
        <div>

        <div className="w-full flex justify-start md:justify-between items-center md:flex-row flex-col gap-4 mb-10 md:mb-0">
            <div className="w-full md:w-fit grid grid-cols-1 md:grid-cols-12 gap-4 items-end my-10">
                <div className="md:col-span-3">
                    <Select label="Filter Invoices" name="invoices" options={options} />
                </div>
                <div className="md:col-span-4">
                    <input
                        type="search"
                        name=""
                        value={searchText}
                        onChange={handleSearchChange}
                        placeholder="Search by resource, name or status..."
                        className="bg-white text-black w-full p-4 text-sm rounded-md border border-[#c4c4c460] active:outline-0 focus:outline-0 placeholder:opacity-70 placeholder:text-xs dark:bg-slate-800 dark:text-white"
                    />
                </div>
            </div>

            <div className="w-full md:w-fit flex justify-center items-center">
                <button
                    className="text-white py-4 px-6 text-xs rounded bg-primaryBlue w-full"
                    onClick={handleMakePayment}
                    disabled={selectedRows.length === 0 || loading}
                >
                    {loading ? (
                        <div className="flex items-end">
                            <InlineSpinner />
                        </div>
                    ) : (
                        <div>
                            Proceed to Make Payment ({transformedTotalAmount})
                        </div>
                    )}
                </button>
            </div>


        </div>

          <div className="overflow-x-auto rounded-md border dark:border-[#c4c4c432]">
            <table className="w-full text-sm text-left overflow-hidden">
              <thead className="bg-white dark:bg-slate-800 px-3 md:px-4 border text-gray-400 dark:border-[#c4c4c432] border-b text-sm font-semibold">
                <tr>
                  <th className="px-6 py-4">
                    <input 
                        type="checkbox" 
                        checked={selectAll} 
                        onChange={handleSelectAll} 
                        className="rounded-md cursor-pointer" 
                    />
                  </th>
                  <th scope="col" className="px-6 py-4 whitespace-nowrap">
                    Invoice no.
                  </th>
                  <th scope="col" className="px-6 py-4 whitespace-nowrap">
                    Resource Name
                  </th>
                  <th scope="col" className="px-6 py-4 whitespace-nowrap">
                    Resource
                  </th>
                  <th scope="col" className="px-6 py-4 whitespace-nowrap">
                    Creation date
                  </th>
                  <th scope="col" className="px-6 py-4 whitespace-nowrap">
                    Due date
                  </th>
                  <th scope="col" className="px-6 py-4 whitespace-nowrap">
                    Status
                  </th>
                  <th scope="col" className="px-6 py-4 whitespace-nowrap">
                    Amount
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white dark:bg-slate-800 px-3 md:px-4 border rounded-b-md dark:border-[#c4c4c432] border-t-2 text-sm">
                {currentEntries.map((row, index) => (
                  <tr
                    key={index}
                    className="dark:hover:bg-[#ffffff05] hover:bg-brandBlue/25 whitespace-nowrap"
                  >
                    <td className="px-6 py-4">
                      <input
                        type="checkbox"
                        checked={selectedRows.includes(row.id)}
                        onChange={() => handleRowSelection(row.id)}
                        className="rounded-md cursor-pointer" 
                      />
                    </td>
                    <td className="text-start py-4 px-6">{row.invoiceNumber}</td>
                    <td className="text-start py-4 px-6">{row.resourceName}</td>
                    <td className="text-start py-4 px-6">{row.resource}</td>
                    <td className="text-start py-4 px-6">{row.creationDate}</td>
                    <td className="text-start py-4 px-6">{row.dueDate}</td>
                    <td className="text-start py-4 px-6">{row.status}</td>
                    <td className="text-start py-4 px-6">{row.amount}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="grid justify-center gap-4 md:flex md:justify-between items-center mt-4">
            <p className="text-gray-400 text-sm">
              Showing{" "}
              <span className="font-medium text-black dark:text-white">
                {indexOfFirstEntry + 1}
              </span>{" "}
              to{" "}
              <span className="font-medium text-black dark:text-white">
                {Math.min(indexOfLastEntry, invoiceListMap?.length)}
              </span>{" "}
              of{" "}
              <span className="font-medium text-black dark:text-white">
                {invoiceListMap?.length}
              </span>{" "}
              results
            </p>
            <div className=" flex gap-3">
              <button
                className={`bg-white dark:bg-primaryBlue border dark:border-none rounded-md py-3 px-5 text-sm ${currentPage === 1 ? "opacity-50" : "opacity-100"}`}
                onClick={handlePreviousPage}
                disabled={currentPage === 1}
              >
                Previous
              </button>
              <button
                className={`bg-white dark:bg-primaryBlue border dark:border-none rounded-md py-3 px-5 text-sm ${currentPage === totalPages ? "opacity-50" : "opacity-100"}`}
                onClick={handleNextPage}
                disabled={currentPage === totalPages}
              >
                Next
              </button>
            </div>
          </div>
        </div>

        :

        <>
          <div className="md:px-52 text-center grid text-gray-500 mt-32">
            <div>It looks like you don't have a billing history yet. Once you make a payment for a service, your billing details will appear here.</div>
            <Link to={"/client-area/vps"} className="border border-[#c4c4c450] dark:border-[#c4c4c416] rounded-md p-3 flex items-center justify-self-center mt-8 w-fit text-xs">
              <span>View VPS plans</span>
              <span className="ps-2">
                <img src={arrow} alt="" />
              </span>
            </Link>
          </div>
        
        </>
      
      }
      
      <ToastContainer
       position="top-right"
       autoClose={3000}
       hideProgressBar={true}
       newestOnTop={false}
       pauseOnHover
       theme="colored"
      />
    </div>
  );
};

export default InvoiceDetails;
