import Axios from "axios";
import { APP_SECRET_KEY } from "../constants";
import CryptoJS from "crypto-js";
// import { apiKey } from "../../apiKeys";
// import { baseURL } from "../../apiKeys";

export const baseUrlNoAuth = Axios.create({
  baseURL: "https://cvgcoops.com.ng/api/v1/",
  //baseURL: "https://staging.cvgcoops.com.ng/api/v1/",
  headers: { "Content-Type": "application/json" },
});

export const baseUrlWithAuth = Axios.create({
   baseURL: "https://cvgcoops.com.ng/api/v1/",
   //baseURL: "https://staging.cvgcoops.com.ng/api/v1/",
  headers: {
    "Content-Type": "application/json",
  },
});

export const virtualAccountBaseUrl = Axios.create({
  baseURL: "https://zippyworld.net/zippy_event_third_party/v1/api",
  headers: {
    "x-api-key": "6128892",
    "Content-Type": "application/json",
  },
});

baseUrlWithAuth.interceptors.request.use(
  (config) => {
    const decryptedData = CryptoJS.AES.decrypt(localStorage.getItem("userSessionData"), APP_SECRET_KEY).toString(CryptoJS.enc.Utf8);
    const parsedData = JSON.parse(decryptedData); 
    const accessKey = parsedData.access;
    if (accessKey) {
      config.headers["Authorization"] = `Bearer ${accessKey}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);
