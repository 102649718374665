import { useSelector } from "react-redux";
import { Link, Outlet } from "react-router-dom";
import React, { useEffect, useContext, useState } from "react";
import { useDispatch } from "react-redux";
import {
  createSessionVirtualAccount,
  createUserVirtualAccount,
  getUserVirtualAccount,
  createPaymentLink,
} from "../../../hooks/local/userReducer";
import { CartContext } from "../../../components/cartContext";
import edit from "../../../assets/svg-icons/edit.svg";
import dewImage from "../../../assets/svg-icons/dewImage.svg";
import arrow from "../../../assets/svg-icons/blueArrow.svg";
import close from "../../../assets/svg-icons/closeBrand.svg";
import Input from "../../../components/input";
import { useFormik } from "formik";
import * as Yup from "yup";

const Home = () => {
  const [walletDetails, setWalletDetails] = useState(false);
  const [paymentLink, setPaymentLink] = useState("");
  const [linkGenerated, setLinkGenerated] = useState(false);
  const [amountValue, setAmountValue] = useState("");
  const { setCartNumber } = useContext(CartContext);
  const selectedProject = useSelector((state) => state.user.selectedProject);
  const { virtualAccount = {} } = useSelector((state) => state.user);
  const userSessionData = useSelector((state) => state.user.userSessionData);
  const currency = useSelector((state) => state.user.currency);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserVirtualAccount());
  }, [dispatch]);

  const walletBalance = virtualAccount ? (currency === "USD" ?virtualAccount.dollar_balance : virtualAccount.balance) : null;
  const accountName = virtualAccount ? virtualAccount.acct_name : null;
  const accountNumber = virtualAccount ? virtualAccount.acct_num : null;

  document.title = "Client area - CloudVantage";

  const handleCreateVirtualAcccount = async () => {
    const name = userSessionData?.fullname;
    const phone = userSessionData?.phone;

    let virtualAccountData = {
      customerName: name,
      phoneNumber: `1${phone}`,
      mainPhoneNumber: "09069434255",
    };

    const { payload: virtAcctPayload } = await dispatch(
      createUserVirtualAccount(virtualAccountData)
    );

    if (virtAcctPayload.status_code === "200") {
      const accountNumber = virtAcctPayload?.data?.accountNumber;
      const accountName = virtAcctPayload?.data?.accountName;

      let sessionVirtualAccountData = {
        acct_num: accountNumber ? accountNumber : "",
        acct_name: accountName ? accountName : "",
        pref_deno: "naira",
      };

      const { payload } = await dispatch(
        createSessionVirtualAccount(sessionVirtualAccountData)
      );
      if (payload.statuscode === "00") {
        dispatch(getUserVirtualAccount());
      }
    }
  };

  const dollarPaymentLink = useFormik({
    initialValues: {
      amount: amountValue,
    },
    validationSchema: Yup.object({
      amount: Yup.string().required("Amount cannot be empty"),
    }),
    onSubmit: async (values, { resetForm }) => {
      const { amount } = values;
      const { payload } = await dispatch(createPaymentLink({ amount }));

      const payment_link = payload?.payment_link;
      setPaymentLink(payment_link);

      if (payload.statuscode === "00") {
        setLinkGenerated(true);
        resetForm();
      }
    },
  });

  const handleAmountChange = (e) => {
    setAmountValue(e.target.value);
    dollarPaymentLink.setFieldValue("amount", e.target.value);
  };

  const handletoggleWalletDetails = () => {
    setWalletDetails((prev) => !prev);
    setLinkGenerated(false);
    setAmountValue("");
    dollarPaymentLink.resetForm();
  };

  return (
    <div className="grid grid-cols-1 lg:grid-cols-12">
      <div className="px-4 md:px-10 py-6 lg:col-span-12 min-h-screen">
        <div
          className={`grid md:flex gap-4 md:justify-between md:items-start pb-6 ${
            !accountName || !accountNumber ? "md:items-center" : ""
          }`}
        >
          <div className="flex gap-3">
            <div className="bg-brandBlue dark:bg-brandBlue/10 rounded-md w-20 flex items-center justify-center">
              <img src={dewImage} alt="" className="h-5" />
            </div>
            <div className="dark:text-white">
              <p className="text-sm">{selectedProject?.name || ""}</p>
              <p className="mb-2 text-xs text-gray-500">
                {selectedProject?.description || ""}
              </p>
              <div className="flex gap-1 items-center text-primaryBlue text-xs">
                <span>
                  <img src={edit} alt="" className="h-[16px]" />
                </span>
                <Link to={"/client-area/manage"}>Manage project</Link>
              </div>
            </div>
          </div>

          <div className="cursor-pointer" onClick={handletoggleWalletDetails}>
            <div className="w-fit border border-[#c4c4c450] dark:border-[#c4c4c416] rounded-md p-3 flex items-center divide-x dark:divide-gray-500 mb-3">
              <div className="grid text-xs dark:text-white pe-4">
                <span className=" text-gray-500">Wallet balance:</span>

                <span className="!text-xl !lg:text-3xl !font-bold !text-black">
                <span  dangerouslySetInnerHTML={{ __html: currency === "NGN" ? "&#x20A6;" : "&#x24;" }} /> 
                  {walletBalance || walletBalance === 0
                    ? `${walletBalance}`
                    : "0.00"}
                </span>
              </div>
              <span className="ps-2">
                <img src={arrow} alt="" />
              </span>
            </div>
            {!(accountName || accountNumber) && (
              <button
                className="text-white p-3 text-xs rounded bg-primaryBlue md:w-full w-fit"
                onClick={handleCreateVirtualAcccount}
              >
                Create Account Number
              </button>
            )}
          </div>
        </div>

        {walletDetails && (
          <div className="w-full fixed h-screen z-50 bg-black/70 top-0 left-0 px-4 flex items-end md:items-center justify-center">
            <div className="bg-white dark:bg-[#141D26] rounded w-full md:w-[40%] lg:w-[30%] dark:text-white max-h-[80%] mb-4 md:mb-0 overflow-auto">
              <div className="flex justify-between items-center text-sm p-6 border-b dark:border-b-[#c4c4c432]">
                <div className="flex gap-2 items-center">
                  <span className="dark:text-white">
                    {currency === "USD" ? "Dollar" : "Naira"} account details
                  </span>
                </div>
                <img
                  src={close}
                  onClick={handletoggleWalletDetails}
                  className="h-5 cursor-pointer"
                  alt=""
                />
              </div>

            <div className="grid p-6 text-sm">
                {accountName || accountNumber ? (
                  linkGenerated ? (
                    <div>
                      <Input type="text" disabled value={paymentLink} />
                      {paymentLink && (
                        <a
                          href={paymentLink}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <button
                            className="bg-primaryBlue text-white rounded p-4 text-xs w-full mt-5"
                            onClick={handletoggleWalletDetails}
                          >
                            Make Payment
                          </button>
                        </a>
                      )}
                    </div>
                  ) : (
                    <div className="text-sm grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-6">
                      {currency === "USD" && (
                        <>
                          {/* Former Option 2 now becomes Option 1 */}
                          <div className="grid col-span-2">
                            <span className="mb-5">
                              <p className="mb-1">OPTION 1:</p>
                              <p>Generate Payment Link</p>
                            </span>
                            <>
                              <Input
                                type="text"
                                label="Amount"
                                placeholder="Enter amount"
                                name="amount"
                                value={amountValue}
                                onBlur={dollarPaymentLink.handleBlur}
                                onChange={handleAmountChange}
                                onError={
                                  dollarPaymentLink.errors.amount &&
                                  dollarPaymentLink.touched.amount
                                    ? dollarPaymentLink.errors.amount
                                    : null
                                }
                              />
                              <button
                                className="bg-primaryBlue text-white rounded p-4 text-xs w-full mt-5"
                                type="button"
                                onClick={(e) => {
                                  e.preventDefault();
                                  dollarPaymentLink.handleSubmit(e);
                                }}
                              >
                                Generate Payment Link
                              </button>
                            </>
                          </div>

                          {/* Former Option 1 now becomes Option 2 */}
                          <div className="col-span-2 mt-5">
                            <p className="mb-1">OPTION 2:</p>
                            <span>
                              Transfer exact amount to this account and call:
                              <a
                                href="tel:09069434255"
                                className="text-blue-500 underline mx-1"
                              >
                                09069434255
                              </a>
                              or send a mail with receipt of payment to
                              <a
                                href="mailto:support@cloudvantage.com.ng"
                                className="text-blue-500 underline ml-1"
                              >
                                support@cloudvantage.com.ng
                              </a>
                            </span>
                          </div>
                        </>
                      )}
                      <div className="grid">
                        <span>Bank name:</span>
                        <span className="text-gray-500">
                          {currency === "USD"
                            ? "Guaranty Trust Bank"
                            : "Providus Bank"}
                        </span>
                      </div>
                      <div className="grid">
                        <span>Account number:</span>
                        <span className="text-gray-500">
                          {currency === "USD"
                            ? "0891825462"
                            : accountNumber || ""}
                        </span>
                      </div>
                      <div className="grid md:col-span-2 border-b dark:border-b-[#c4c4c432] pb-5">
                        <span>Account name:</span>
                        <span className="text-gray-500">
                          {currency === "USD"
                            ? "Cloudvantage Limited"
                            : accountName || ""}
                        </span>
                      </div>
                    </div>
                  )
                ) : (
                  ""
                )}

                <Link
                  to={"/settings"}
                  className="mt-6 w-fit border border-[#c4c4c450] dark:border-[#c4c4c416] rounded-md p-3 flex items-center text-xs text-primaryBlue"
                >
                  <span>Currency settings</span>
                  <span>
                    <img src={arrow} alt="" />
                  </span>
                </Link>
              </div>
            </div>
          </div>
        )}

        <div className="border-t border-t-[#c4c4c460] pt-6">
          <Outlet />
        </div>
      </div>
    </div>
    
  );
};

export default Home;
